import { IconType } from '../types/icons.type'

const CloseQuoteIcon = ({ size = '24', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22 11.65H16.2C14.67 11.65 13.62 10.49 13.62 9.07V5.85C13.62 4.43 14.67 3.27 16.2 3.27H19.42C20.84 3.27 22 4.43 22 5.85V11.65ZM22 11.65C22 17.7 20.87 18.7 17.47 20.72M10.38 11.65V5.85C10.38 4.43 9.22 3.27 7.8 3.27H4.57C3.04 3.27 1.99 4.43 1.99 5.85V9.07C1.99 10.49 3.04 11.65 4.57 11.65H10.37C10.37 17.7 9.24 18.7 5.84 20.72'
        stroke={fillColor}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CloseQuoteIcon
