import { createSlice } from '@reduxjs/toolkit'

import { verifyEmailThunk } from '../thunks/auth.thunk'
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from './../../../../types/api.types'

export type VerifyUserState = {
  requestResponse: ApiRequestDataType
}

export const initialState: VerifyUserState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
  },
}

const verifyEmailSlice = createSlice({
  name: 'verifyEmailSlice',
  initialState: initialState,
  reducers: {
    resetVerifyEmailState: (state) => {
      state.requestResponse.status = ApiRequestStatus.IDLE
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyEmailThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(verifyEmailThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.data.message
      })
      .addCase(verifyEmailThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetVerifyEmailState } = verifyEmailSlice.actions
export default verifyEmailSlice.reducer
