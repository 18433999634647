import { useState } from 'react'
import { Link } from 'react-router-dom'

import globals from '../../../../globals.module.css'
import styles from './music.module.css'

import UseWindowSize from '../../../../lib/hooks/get-window-size.hook'
import { artistData } from '../../../../repository/data.repository'
import MusicCardMolecule from '../../../molecules/music-card/MusicCard.molecule'
import Pagination2 from '../../../organisms/pagination/Pagination.organism'
import SimpleCarousel from '../../../organisms/simple-carousel/SimpleCarousel.organism'

const MusicTemplate = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0 as number)
  const handlePrev2 = () => {
    if (activeIndex !== 0) {
      setActiveIndex(activeIndex - 1)
    }
  }

  const handleNext2 = () => {
    if (activeIndex < Math.ceil(artistData.length / numberOfCardDisplay()) - 1) {
      setActiveIndex(activeIndex + 1)
    }
  }

  const handleIndex = (index = 0) => {
    setActiveIndex(index - 1)
  }

  const { width } = UseWindowSize()

  const numberOfCardDisplay = () => {
    if (width <= 490) {
      return 1
    } else if (width <= 844) {
      return 3
    } else if (width <= 1233) {
      return 6
    } else {
      return 8
    }
  }

  return (
    <section
      className={`${globals['full-width']} ${globals['p-desktop']} ${globals.flex} ${globals['flex-column']} ${globals['gap-24']} ${styles.music__template}`}
    >
      <div
        className={`${globals.flex} ${globals['s-b']} ${globals['a-center']} ${globals['gap-24']}`}
      >
        <h2>Popular Artists</h2>
        <Link to='/'>View All</Link>
      </div>
      <SimpleCarousel items={artistData.length} activeIndex={activeIndex}>
        <div className={`${styles.grid}`}>
          {artistData
            .slice(activeIndex * numberOfCardDisplay(), numberOfCardDisplay() * (activeIndex + 1))
            .map((data, index) => {
              return (
                <div key={index}>
                  <Link to={'/artist/:handle'}>
                    <MusicCardMolecule image={data.image} name={data.name} />
                  </Link>
                </div>
              )
            })}
        </div>
      </SimpleCarousel>
      <Pagination2
        handleIndex={handleIndex}
        handleNext={handleNext2}
        handlePrev={handlePrev2}
        length={Math.ceil(artistData.length / numberOfCardDisplay())}
        index={activeIndex}
      />
    </section>
  )
}

export default MusicTemplate
