import { IconType } from '../types/icons.type'

const GlobeIcon = ({ size = '24', fillColor = 'var(--ROYALTY)' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 3H9C7.05003 8.84157 7.05003 15.1584 9 21H8M15 3C16.95 8.84157 16.95 15.1584 15 21'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 16V15C8.84157 16.95 15.1584 16.95 21 15V16M3 9C8.84157 7.05003 15.1584 7.05003 21 9'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default GlobeIcon
