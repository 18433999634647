import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import globals from '../../globals.module.css'
import styles from './details.module.css'

import ButtonAtom from '../../components/atoms/button/Button.atom'
import AudioCardComponent from '../../components/molecules/audio-card/AudioCard.molecule'
import GeneralLayout from '../../layouts/general/General.layout'
import Internalization from '../../layouts/internationalization/Internalization'
import { popularSongs } from '../../repository/data.repository'
import { ButtonTypes } from '../../types/enum.type'
import { IconRepository } from '../../repository/icons.repository'
import AlbumsMolecule from '../../components/molecules/albums/Albums.molecule'

const ArtistDetailsPage = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Internalization>
      <GeneralLayout>
        <div className={`${styles.hero} ${globals['p-desktop']} ${globals['full-width']}`}>
          <span className={styles.genre}>Makossa Hit</span>

          <div
            className={`${styles.user__profile} ${globals.flex} ${globals['a-center']} ${globals['gap-8']}`}
          >
            <div className={styles.line}></div>
            <div>
              <div className={`${styles.image}`}>
                <img src='/images/team/emma.webp' width='100%' height='100%' alt='user' />
              </div>
            </div>
            <div className={styles.line}></div>
          </div>

          <div
            className={`${globals.flex} ${globals['flex-column']} ${styles.name} ${globals['center-items']}`}
          >
            <h3>Nchia Emmanuela Ekei</h3>
            <h4>
              <span>400+</span> Followers
            </h4>
            <div
              className={`${globals.flex} ${globals['gap-8']}`}
              style={{ paddingBottom: '12px', marginTop: '-10px' }}
            >
              <a href='https://twitter.com'>
                <IconRepository.TwitterIcon size='14' fillColor='#fff' />
              </a>
              <a href='https://linkedin.com'>
                <IconRepository.LinkedinIcon size='14' fillColor='#fff' />
              </a>
            </div>
            <div>
              <ButtonAtom label='Follow' buttonType={ButtonTypes.PRIMARY} />
            </div>
          </div>

          <div
            className={`${styles.projects} ${globals['flex-column']} ${globals.flex} ${globals['gap-24']}`}
          >
            <h2>Singles</h2>
            <div className={`${styles.grid}`}>
              {popularSongs.map((data, index) => {
                return (
                  <div key={index}>
                    <AudioCardComponent
                      image={data.image}
                      artistName={data.artistName}
                      songName={data.songName}
                      index={index}
                    />
                  </div>
                )
              })}
            </div>
          </div>

          <div
            className={`${styles.projects} ${globals['flex-column']} ${globals.flex} ${globals['gap-24']}`}
          >
            <h2>Albums</h2>
            <div className={`${styles.grid}`}>
              {popularSongs.map((data, index) => {
                return (
                  <div key={index}>
                    <AlbumsMolecule img={data.image} title={data.songName} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </GeneralLayout>
    </Internalization>
  )
}

export default ArtistDetailsPage
