export const appConstants = {
  breakPoints: {
    XL: 1440,
    L: 1133,
    M: 834,
    S: 575,
  },
  employmentTypes: [
    'Part Time',
    'Full Time',
    'Apprenticeship',
    'Self Employed',
    'Freelance',
    'Contract',
    'Internship',
  ],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  storageKeys: {
    phoneCodes: 'certiup-key-001',
    previousRoute: 'certiup-key-002',
    currentUser: 'certiup-key-003',
    appLang: 'i18nextLng',
  },
  routes: {
    landingPage: '/:lang?',
    landingPageRef: '/',
    team: '/:lang?/team',
    teamRef: '/team',
    userProfile: '/:lang?/app/manage-profile',
    courseUpload: '/admin/course-upload',
    courseManagement: '/admin/create-course',
    testView: '/test',
    audioDetails: '/audio/:id',
    artistDetails: '/artist/:handle',
    dataCollection: '/:lang?/questionaires',
    dataCollectionRef: '/questionaires',
    coursesRef: '/app/courses/:category/:slug',
    courses: '/:lang?/app/courses/:category/:slug',
    welcome: '/welcome',
    auth: {
      loginRefLink: '/auth/login',
      login: '/:lang?/auth/login',
      signup: '/auth/signup',
      forgotPassword: '/auth/forgot-password',
      verifyEmail: '/auth/verify-email',
      createNewPassword: '/reset-password/:token',
    },
    adminManagement: {
      index: '/admin/course-management/:id',
      sections: 'edit/:id/section',
      courseThumbnail: 'edit/:id/thumbnail',
      certification: 'edit/:id/certifications',
      externalResources: 'edit/:id/external-docs',
      topics: 'edit/:id/section/:sectionid/manage',
      topicContent: 'edit/:id/section/:sectionid/topic/:topicid/manage',
    },
    dashboard: {
      refLink: '/app/dashboard',
      subscription: 'subscriptions',
      events: 'events',
      index: '/:lang?/app/dashboard',
      profile: 'profile',
      certfications: 'certifications',
      courseOutline: 'certifications/courses/:slug',
      settings: 'settings',
      enrolled: 'enrolled-certifications',
      badges: 'badges',
      cvbuilder: 'cv-builder',
    },
  },
  supportedLangs: { en: 'en', fr: 'fr', de: 'de' },
  sectionIds: {
    home: {
      hero: '#hero',
      academy: '/#academy',
      howItWorks: '#how-it-works',
      testimonials: '#testimonials',
    },
  },
  colorVariables: {
    darkBlue: 'var(--DARK_BLUE_COLOR)',
    royalty: 'var(--ROYALTY)',
    red: 'var(--RED_COLOR)',
    green: 'var(--GREEN_COLOR)',
    yellow: 'var(--YELLOW_COLOR)',
    white: 'var(--WHITE_COLOR)',
    dark: 'var(--DARK_COLOR)',
  },

  modalLabels: ['skillsModal', 'testimonyModal', 'teamModal'] as const,
}
