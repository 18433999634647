import globals from '../../../globals.module.css'
import styles from './musiccard.module.css'

// import { ImageAssets } from '../../../app/assets'
import { IconRepository } from '../../../repository/icons.repository'

const MusicCardMolecule = ({ image, name }: { image: string; name: string }) => {
  return (
    <article className={`${styles.music__card} ${globals['full-width']}`}>
      <div className={`${styles.image}`}>
        <img width='100%' height='100%' src={image} alt='title' />
      </div>
      <div
        className={`${styles.content} ${globals.flex} ${globals['flex-column']} ${globals['gap-8']}`}
      >
        <h3>{name}</h3>
        <div className={`${globals.flex} ${globals['gap-8']}`}>
          <a href='https://twitter.com'>
            <IconRepository.TwitterIcon size='14' fillColor='#fff' />
          </a>
          <a href='https://linkedin.com'>
            <IconRepository.LinkedinIcon size='14' fillColor='#fff' />
          </a>
        </div>
      </div>
    </article>
  )
}

export default MusicCardMolecule
