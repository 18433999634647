import { useState } from 'react'
import { Link } from 'react-router-dom'

import globals from '../../../../globals.module.css'
import styles from './audio.module.css'

import UseWindowSize from '../../../../lib/hooks/get-window-size.hook'
import { popularSongs } from '../../../../repository/data.repository'
import AudioCardComponent from '../../../molecules/audio-card/AudioCard.molecule'
import Pagination2 from '../../../organisms/pagination/Pagination.organism'
import SimpleCarousel from '../../../organisms/simple-carousel/SimpleCarousel.organism'

const AudioTemplate = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0 as number)
  const handlePrev2 = () => {
    if (activeIndex !== 0) {
      setActiveIndex(activeIndex - 1)
    }
  }

  const handleNext2 = () => {
    if (activeIndex < Math.ceil(popularSongs.length / numberOfCardDisplay()) - 1) {
      setActiveIndex(activeIndex + 1)
    }
  }

  const handleIndex = (index = 0) => {
    setActiveIndex(index - 1)
  }

  const { width } = UseWindowSize()

  const numberOfCardDisplay = () => {
    if (width <= 490) {
      return 2
    } else if (width <= 844) {
      return 4
    } else if (width <= 1233) {
      return 6
    } else {
      return 8
    }
  }

  return (
    <section
      className={`${globals['full-width']} ${globals['p-desktop']} ${globals.flex} ${globals['flex-column']} ${globals['gap-24']} ${styles.music__template}`}
    >
      <div
        className={`${globals.flex} ${globals['s-b']} ${globals['a-center']} ${globals['gap-24']}`}
      >
        <h2>Trending Songs</h2>
        <Link to='/'>View All</Link>
      </div>
      <SimpleCarousel items={popularSongs.length} activeIndex={activeIndex}>
        <div className={`${styles.grid}`}>
          {popularSongs
            .slice(activeIndex * numberOfCardDisplay(), numberOfCardDisplay() * (activeIndex + 1))
            .map((data, index) => {
              return (
                <div key={index}>
                  <AudioCardComponent
                    image={data.image}
                    artistName={data.artistName}
                    songName={data.songName}
                    index={index}
                  />
                </div>
              )
            })}
        </div>
      </SimpleCarousel>
      <Pagination2
        handleIndex={handleIndex}
        handleNext={handleNext2}
        handlePrev={handlePrev2}
        length={Math.ceil(popularSongs.length / numberOfCardDisplay())}
        index={activeIndex}
      />
    </section>
  )
}

export default AudioTemplate
