import { useEffect, useRef, useState } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'

import styles from '../auth.module.css'

import axios, { isAxiosError } from 'axios'
import { appConstants } from '../../../app/constants'
import {
  NotificationTitleType,
  showNotification,
} from '../../../app/features/notifcation/notification.slice'
import ButtonAtom from '../../../components/atoms/button/Button.atom'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import { IconRepository } from '../../../repository/icons.repository'
import { ButtonTypes } from '../../../types/enum.type'

const ForgotPasswordPage = (): JSX.Element => {
  const email = useRef<HTMLInputElement | null>(null)
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const newAuthState = useAppSelector((state) => state.newAuthSlice)

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault()

    if (email.current?.value.length === 0) {
      dispatch(
        showNotification({
          message: 'Your email is required.',
          title: NotificationTitleType.ERROR,
        }),
      )
      return
    }

    try {
      setLoading(true)
      const body = {
        email: email.current?.value as string,
      }

      await axios
        .post('http://localhost:8000/api/auth/forgot-password', body, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          dispatch(
            showNotification({
              message: 'Please check your email, for further instructions.',
              title: NotificationTitleType.SUCCESS,
            }),
          )
        })
    } catch (e) {
      if (isAxiosError(e) && e.response?.data) {
        dispatch(
          showNotification({
            message: e.response.data.message,
            title: NotificationTitleType.ERROR,
          }),
        )
        return
      }

      dispatch(
        showNotification({
          message: 'Error! Please ensure you have internet connection and have filled every field.',
          title: NotificationTitleType.ERROR,
        }),
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (newAuthState?.loginState?.tokens?.user != null) {
      navigate('/app/dashboard')
    }
  }, [newAuthState])

  return (
    <div className={styles.auth}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='1920'
        height='793.227'
        viewBox='0 0 1920 793.227'
        className={styles.svg}
      >
        <g id='Group_3' data-name='Group 3' transform='translate(0 -0.098)'>
          <path
            id='Path_1'
            data-name='Path 1'
            d='M96.749,0H884.56L801.632,12.658H0Z'
            transform='translate(1084.456 781.429) rotate(-20)'
            fill='#f51767'
          />
          <path
            id='Path_3'
            data-name='Path 3'
            d='M166.333,0H1520.762L1378.191,12.658H0Z'
            transform='translate(1424.29 11.915) rotate(159)'
            fill='#f51767'
          />
          <path
            id='Path_2'
            data-name='Path 2'
            d='M26.464,0,563.028,1.074,544.2,11.812,0,11.954Z'
            transform='translate(1037.387 773.767) rotate(-20)'
            fill='#5237aa'
          />
        </g>
      </svg>

      <div className={styles.auth__form}>
        <div className={styles.captions}>
          <Link to={'/'}>
            <IconRepository.LogoIcon width='250' />
          </Link>
          <h4>Quick, efficient and a secure way to reset your password</h4>
        </div>
        <form className={styles.form}>
          <h2>Forgot Password</h2>

          <div className={styles.form__control}>
            <div className={styles.label}>
              <label htmlFor='email'>Email</label>
            </div>
            <div className={styles.input__arena}>
              <div className={styles.icon__left}>
                <AiOutlineMail size={17} />
              </div>
              <input type='text' ref={email} placeholder='Email' name='email' />
            </div>
          </div>

          <div style={{ marginTop: '4rem' }}>
            <ButtonAtom
              buttonType={ButtonTypes.PRIMARY}
              label='Request Password Change'
              fontSize='1.8rem'
              rounded={false}
              onClick={handleSubmit}
              padding='10px 24px'
              height='56px'
              loading={loading}
            />
          </div>
          <h4>
            Remember your password? <Link to={appConstants.routes.auth.loginRefLink}>Login</Link>
          </h4>
        </form>
      </div>
    </div>
  )
}

export default ForgotPasswordPage
