import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { LoginResponseType } from '../../../../types'

export type LoginUserState = {
  loginState: LoginResponseType | null
}

const userInfoFromStorage = localStorage.getItem('loginResponse')

export const initialState: LoginUserState = {
  loginState: userInfoFromStorage ? JSON.parse(userInfoFromStorage) : null,
}

const newAuthSlice = createSlice({
  name: 'newAuthSlice',
  initialState: initialState,
  reducers: {
    resetNewAuthState: (state) => {
      state.loginState = null
    },
    updateNewAuthState: (state, action: PayloadAction<{ data: LoginResponseType }>) => {
      state.loginState = action.payload.data
    },
  },
})

export const { resetNewAuthState, updateNewAuthState } = newAuthSlice.actions
export default newAuthSlice.reducer
