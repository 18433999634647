import { Suspense, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import { appConstants } from './app/constants'
import {
  NotificationState,
  NotificationTitleType,
  resetNotificationState,
  showNotification,
} from './app/features/notifcation/notification.slice'
import { getPhoneNumbersThunk } from './app/features/phone-number/thunks/phone-number.thunk'
import { loadScript } from './app/features/scripts'
import { RootState } from './app/store/store'
import NotificationAtom from './components/atoms/notification/Notification.atom'
import AppLoader from './components/loaders/app-loader/App.loader'
import PopupModalMolecule from './components/molecules/popup/PopupModal.molecule'
// import TranslatorMolecule from './components/molecules/translator/Translator.molecule'
import { useTheme } from './layouts/theme/Theme.layout'
import { useAppDispatch, useAppSelector } from './lib/hooks'
import {
  googleAuthScriptId,
  googleAuthScriptSrc,
  palcesScriptId,
  placesScriptSrc,
} from './lib/utils/google'
import LandingPage from './pages/landing/Landing.page'
// import TeamPage from './pages/team/Team.page'
import TranslatorMolecule from './components/molecules/translator/Translator.molecule'
import ArtistDetailsPage from './pages/artist-details/ArtistDetails.page'
import AudioDetailsPage from './pages/audio-details/AudioDetails.page'
import CreateNewPasswordPage from './pages/auth/create-new-password/CreateNewPassword.page'
import ForgotPasswordPage from './pages/auth/forgot-password/ForgotPassword.page'
import LoginPage from './pages/auth/login/Login.page'
import RegisterPage from './pages/auth/register/Register.page'
import VerifyAccountPage from './pages/auth/verify-account/VerifyAccount.page'
import DashboardPage from './pages/dashboard/Dashboard.page'
import EventsView from './pages/dashboard/views/events/Events.view'
import IndexView from './pages/dashboard/views/index/Index.view'
import ProfileView from './pages/dashboard/views/profile/Profile.view'
import SubscriptionView from './pages/dashboard/views/subscriptions/Subscription.view'
import OnboardingPage from './pages/welcome_screen/WelcomeScreen'
import { languageData } from './repository/language'
import { LocalStorageService } from './services/storage/localstorage.service'
import { ApiRequestStatus } from './types/api.types'

const localStorage = new LocalStorageService()

const App = () => {
  const dispatch = useAppDispatch()
  const { requestResponse } = useAppSelector((state: RootState) => state.phoneNumberSlice)
  const notificationState = useAppSelector((state: RootState) => state.notificationSlice)

  const { i18n, t } = useTranslation(['home', 'main'])
  const [translationPopup, setTranslationPopup] = useState(false)
  const { theme } = useTheme()

  const scripts = useAppSelector((state) => state.scripts)
  const scriptLoadRef = useRef(false)

  const languageLocalConfig = useCallback(async (local: string) => {
    localStorage.storeAppLang(local)
    await i18n.changeLanguage(local)

    // eslint-disable-next-line
  }, [])

  const langStored = localStorage.getAppLang().appLang ? localStorage.getAppLang().appLang : 'en'
  const handleLanguageConvertion = async (lang: string) => {
    localStorage.storeAppLang(lang)

    await i18n.changeLanguage(lang)
  }
  // const preferencesPath = [
  //   '/questionaires',
  //   '/en/questionaires',
  //   '/de/questionaires',
  //   '/fr/questionaires',
  // ]

  useEffect(() => {
    if (
      localStorage.getAppLang().appLang !== undefined &&
      localStorage.getAppLang().appLang !== null
    ) {
      languageLocalConfig(localStorage.getAppLang().appLang as string)
    } else {
      languageLocalConfig('en')
    }

    // eslint-disable-next-line
  }, [window.location.pathname])

  useEffect(() => {
    if (requestResponse.data !== undefined && requestResponse.data !== null) {
      return
    } else {
      dispatch(getPhoneNumbersThunk())
    }
  }, [])

  useEffect(() => {
    if (notificationState.state === NotificationState.ACTIVE) {
      setTimeout(() => {
        dispatch(resetNotificationState())
      }, 5000)
    }
  }, [notificationState])

  useEffect(() => {
    if (requestResponse.status === ApiRequestStatus.REJECTED) {
      dispatch(
        showNotification({
          message: requestResponse.error?.message as string,
          title: NotificationTitleType.ERROR,
        }),
      )
    }
  }, [requestResponse])

  useEffect(() => {
    if (scripts[palcesScriptId] == null && !scriptLoadRef.current) {
      dispatch(loadScript({ id: palcesScriptId, src: placesScriptSrc }))
    }
    if (scripts[googleAuthScriptId] == null && !scriptLoadRef.current) {
      dispatch(loadScript({ id: googleAuthScriptId, src: googleAuthScriptSrc }))
    }
    scriptLoadRef.current = true
  }, [])

  return (
    <Suspense fallback={<AppLoader />}>
      <div
        style={
          {
            ...theme,
          } as React.CSSProperties
        }
      >
        <Router>
          <TranslatorMolecule
            action={() => {
              setTranslationPopup(!translationPopup)
            }}
          />
          <NotificationAtom />
          {translationPopup ? (
            <PopupModalMolecule togglePopup={setTranslationPopup}>
              <div className='popup'>
                <h2>{t('Choose Language', { ns: ['main', 'home'] })}</h2>
                <h3>
                  {t(
                    'Please we are still working and doing our best to have a suitable platform available in all languages. If you find some translation errors please write us at',
                    { ns: ['main', 'home'] },
                  )}{' '}
                  <a target='_blank' rel='noreferrer' href='mailto:admin@afrikmuzik.com'>
                    admin@afrikmuzik.com
                  </a>
                  .
                  {t('AfrikMuzik is available in the following languages:', {
                    ns: ['main', 'home'],
                  })}
                </h3>

                <div className='langs'>
                  {languageData.map((lang, index) => {
                    return (
                      <button
                        key={index}
                        className={langStored === lang?.id ? 'active' : ''}
                        onClick={(e) => {
                          handleLanguageConvertion(e.currentTarget.id)
                        }}
                        id={lang?.id}
                      >
                        {t(lang?.name as string, { ns: ['main', 'home'] })}
                      </button>
                    )
                  })}
                </div>
              </div>
            </PopupModalMolecule>
          ) : (
            ''
          )}
          {requestResponse.status === ApiRequestStatus.PENDING ? <AppLoader /> : ''}
          <Routes>
            <Route path={appConstants.routes.landingPage} element={<LandingPage />} />
            <Route path={appConstants.routes.welcome} element={<OnboardingPage />} />
            <Route path={appConstants.routes.audioDetails} element={<AudioDetailsPage />} />
            <Route path={appConstants.routes.artistDetails} element={<ArtistDetailsPage />} />

            <Route path={appConstants.routes.auth.loginRefLink} element={<LoginPage />} />
            <Route path={appConstants.routes.auth.verifyEmail} element={<VerifyAccountPage />} />
            <Route path={appConstants.routes.dashboard.refLink} element={<DashboardPage />}>
              <Route index element={<IndexView />} />
              <Route
                path={appConstants.routes.dashboard.subscription}
                element={<SubscriptionView />}
              />
              <Route path={appConstants.routes.dashboard.events} element={<EventsView />} />
              <Route path={appConstants.routes.dashboard.profile} element={<ProfileView />} />
            </Route>
            <Route path={appConstants.routes.auth.signup} element={<RegisterPage />} />
            <Route
              path={appConstants.routes.auth.forgotPassword}
              element={<ForgotPasswordPage />}
            />
            <Route
              path={appConstants.routes.auth.createNewPassword}
              element={<CreateNewPasswordPage />}
            />

            {/* redirect to the landing page, for screens not found - temporal solution */}
            <Route path='*' element={<Navigate replace to='/' />} />
          </Routes>
        </Router>
      </div>
    </Suspense>
  )
}

export default App
