import { useRef, useState } from 'react'

import globals from '../../../../globals.module.css'
import styles from './banner.module.css'

import { generateRandomArray } from '../../../../lib/utils/generate-random-bars'
import { IconRepository } from '../../../../repository/icons.repository'
import { ButtonTypes } from '../../../../types/enum.type'
import ButtonAtom from '../../../atoms/button/Button.atom'

const sticksArray = generateRandomArray()

const BannerTemplate = () => {
  const [percentage, setPercentage] = useState(0)
  const audioRef = useRef<HTMLAudioElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const getCurrentDuration = (e: any) => {
    const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2)

    setPercentage(+percent)
  }

  const play = () => {
    const audio = audioRef.current
    if (!isPlaying) {
      setIsPlaying(true)
      audio?.play()
    }

    if (isPlaying) {
      setIsPlaying(false)
      audio?.pause()
    }
  }

  const onChange = (value: number) => {
    const audio = audioRef.current
    if (audio) {
      audio.currentTime = (audio.duration / 100) * value
    }
    setPercentage(value)
  }

  const handleShare = (text: string, audioUrl: string, hashtags = ['#music']) => {
    const encodedText = encodeURIComponent(text)
    const encodedAudioUrl = encodeURIComponent(audioUrl)
    const encodedHashtags = hashtags ? hashtags.map((tag) => encodeURIComponent(tag)).join(',') : ''

    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedAudioUrl}&hashtags=${encodedHashtags}`

    window.open(twitterShareUrl, '_blank')
  }

  const handleLinkedInShare = (title: string, summary: string, url: string) => {
    const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      url,
    )}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(summary)}`

    window.open(linkedInShareUrl, '_blank')
  }

  const handleFacebookShare = (url: string, quote: string) => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url,
    )}&quote=${encodeURIComponent(quote)}`

    window.open(facebookShareUrl, '_blank')
  }

  return (
    <main className={`${styles.banner} ${globals['full-width']}`}>
      <div className={`${styles.image}`}>
        <img src='/images/details.jpg' width='100%' height='100%' alt='banner' />
        <div className={`${styles.overlap}`}>
          <span>
            <IconRepository.SkillBadgeIcon fillColor='#fff' size='20' /> Verified Artist
          </span>
          <h2>Daphne Feat. Salatiel</h2>
          <h3>
            <IconRepository.AddressIcon fillColor='#fff' size='18' />
            <span>3,000,000</span> Monthly Listeners
          </h3>

          <div
            onClick={() => {
              play()
            }}
          >
            <span className={`${styles.play} ${isPlaying ? styles.playing : ''}`}>
              {isPlaying ? (
                <IconRepository.PauseVideoIcon size='45' fillColor='var(--WHITE_COLOR)' />
              ) : (
                <IconRepository.PlayVideoIcon size='45' fillColor='var(--WHITE_COLOR)' />
              )}
            </span>
          </div>
        </div>

        <div className={`${styles.share} ${globals.flex} ${globals['gap-12']}`}>
          <a
            onClick={(e) => {
              e.preventDefault()
              handleShare('Hello', 'https://www.google.com', ['ed', 'sheeran'])
            }}
            href='#'
          >
            <IconRepository.TwitterIcon fillColor='#fff' size='18' />
          </a>
          <a
            onClick={(e) => {
              e.preventDefault()
              handleLinkedInShare('Hello', 'https://www.google.com', 'Ed Sheeran')
              handleFacebookShare('Hello', 'https://www.google.com')
            }}
            href='#'
          >
            <IconRepository.LinkedinIcon fillColor='#fff' size='18' />
          </a>
        </div>
      </div>

      <div className={`${styles.bars} ${globals.flex}`}>
        {sticksArray.map((item, index) => {
          return (
            <div
              style={{
                height: `${item + 40}px`,
                background: percentage > index + 1 ? 'var(--ROYALTY)' : '',
                cursor: 'pointer',
              }}
              className={`${styles.bar}`}
              key={index}
              onClick={() => {
                onChange(index + 1)
              }}
            ></div>
          )
        })}
      </div>

      <div className={styles.audio}>
        <audio
          style={{
            width: '100%',
            height: '100%',
          }}
          ref={audioRef}
          onTimeUpdate={getCurrentDuration}
          src={'/audio/music.mp3'}
        ></audio>
      </div>

      <div
        className={`${styles.artist} ${globals.flex} ${globals['gap-12']} ${globals['a-center']}`}
      >
        <div>
          <div className={`${styles.image}`}>
            <img src='/images/details.jpg' width='100%' height='100%' alt='banner' />
          </div>
        </div>
        <h3>Salatiel</h3>
      </div>

      <div className={styles.btn}>
        <ButtonAtom label='Follow' buttonType={ButtonTypes.PRIMARY} />
      </div>
    </main>
  )
}

export default BannerTemplate
