import { IconType } from '../types/icons.type'

const FieldIcon = ({ size = '24', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.29 11.62C1.09 2.69 14.62 -0.870002 16.17 8.5C18.1 8.74 19.51 10.02 20.2 11.62'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.54 8.93C15.06 8.67 15.63 8.53 16.21 8.52M4 15.03H20M6 18.03H18M9 21.03H15'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default FieldIcon
