import { appConstants } from './../app/constants'

export enum IconDirection {
  RIGHT,
  LEFT,
}

export enum ButtonTypes {
  PRIMARY,
  SECONDARY,
  TERTIARY,
}

export enum ButtonLooks {
  OUTLINED,
  SOLID,
}

export enum GenderTypes {
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum ThemesSelectionTypes {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum DarkColorScheme {
  DARK_COLOR = '#fff',
  WHITE_COLOR = '#020b13',
}

export enum LightColorScheme {
  DARK_COLOR = '#020b13',
  WHITE_COLOR = '#fff',
}

export interface Theme {
  '--WHITE_COLOR': DarkColorScheme | LightColorScheme
  '--DARK_COLOR': DarkColorScheme | LightColorScheme
}

export type ThemeType = 'dark' | 'light'

export type ModalType = (typeof appConstants.modalLabels)[number]
