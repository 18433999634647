import globals from '../../../../globals.module.css'
import styles from './subscription.module.css'

import PricingCard from '../../../../components/molecules/pricing-card/PricingCard.molecule'

const plans = [
  {
    planType: 'Starter',
    price: '5000 XAF',
    features: ['Unlimited Music', '24/7 Support', 'Free Shipping'],
  },
  {
    planType: 'Premium',
    price: '20,000 XAF',
    features: ['Unlimited Music', '24/7 Support', 'Free Shipping', 'Premium Support'],
  },
  {
    planType: 'Gold',
    price: '35,000 XAF',
    features: [
      'Unlimited Music',
      '24/7 Support',
      'Free Shipping',
      'Premium Support',
      'Music Promotion',
    ],
  },
]

const SubscriptionView = () => {
  return (
    <section className={`${styles.index} ${globals['full-width']}`}>
      <div className={`${styles.headline}`}>
        <h2>Do More with AfrikMuzik</h2>
        <p>
          Explore more, expand your network, and do more with AfrikMuzik: your yateway to the
          soulful <b></b>eats of Africa!
        </p>
      </div>
      <div className={`${styles.grid}`}>
        {plans.map((plan, index) => {
          return (
            <PricingCard
              key={index}
              planType={plan.planType}
              price={plan.price}
              features={plan.features}
            />
          )
        })}
      </div>
    </section>
  )
}

export default SubscriptionView
