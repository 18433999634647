import React from 'react'

import styles from './album.module.css'

type Props = {
  img: string
  title: string
}
const AlbumsMolecule: React.FC<Props> = ({ img, title }) => {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img src={img} alt={title} />
      </div>
      <h4 className={styles.title}>{title}</h4>
    </div>
  )
}

export default AlbumsMolecule
