import { createAsyncThunk } from '@reduxjs/toolkit'

import { getExceptionPayload } from '../../../../lib/utils/get-exception'
import {
  AuthenticationService,
  verifyUserEmail,
} from '../../../../services/api/authentication.service'
import { ApiRequestErrorType, VerifyEmailType } from '../../../../types/api.types'
import { LoginDataType } from '../../../../types/auth/login.types'

const authService = new AuthenticationService()

export const loginUserThunk = createAsyncThunk(
  '/auth/login',
  async (data: LoginDataType, { rejectWithValue }) => {
    try {
      await authService.loginUser(data)
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)

export const verifyEmailThunk = createAsyncThunk(
  '/auth/verify-email',
  async (data: VerifyEmailType, { rejectWithValue }) => {
    try {
      const response = await verifyUserEmail(data)
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
    }
  },
)
