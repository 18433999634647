import { IconType } from '../types/icons.type'

const EnrolledIcon = ({ size = '24', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.25 14H17.5M9 18H17.5M15.5 2V9.86C15.5 10.3 14.98 10.52 14.66 10.23L12.34 8.09C12.248 8.00337 12.1264 7.95513 12 7.95513C11.8736 7.95513 11.752 8.00337 11.66 8.09L9.34 10.23C9.02 10.52 8.5 10.3 8.5 9.86V2H15.5Z'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default EnrolledIcon
