import AcademyImage from './academy.webp'
import FemaleAvatar from './avatar_female.webp'
import MaleAvatar from './avatar_male.webp'
import Badge from './badge.webp'
import BG from './bg.svg'
import Certified from './certified.svg'
import Google from './google.webp'
import ProfileSetup from './profilesetup.webp'
import heroBG from './test.webp'

export const ImageAssets = {
  AcademyImage,
  Google,
  Badge,
  ProfileSetup,
  FemaleAvatar,
  MaleAvatar,
  Certified,
  BG,
  heroBG,
}
