import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import globals from '../../../globals.module.css'
import styles from './navbar.module.css'

import { ImageAssets } from '../../../app/assets'
import { appConstants } from '../../../app/constants'
import { useAppSelector } from '../../../lib/hooks'
import { UseScrollPosition } from '../../../lib/hooks/get-scroll-position.hook'
import UseWindowSize from '../../../lib/hooks/get-window-size.hook'
import { IconRepository } from '../../../repository/icons.repository'
import { ButtonLooks, ButtonTypes } from '../../../types/enum.type'
import ButtonAtom from '../../atoms/button/Button.atom'

const NavbarOrganism = () => {
  const location = useLocation()
  const position = UseScrollPosition()
  const [locationHash, setLocationHash] = useState(appConstants.sectionIds.home.hero)
  const [mobileActive, setMobileActive] = useState(false)
  const { width } = UseWindowSize()
  const { t } = useTranslation()

  const newAuthState = useAppSelector((state) => state.newAuthSlice)
  const { loginState } = newAuthState

  const scrollToSection = (sectionId: string) => {
    setMobileActive(false)
    try {
      const element = document.querySelector(sectionId)
      if (element !== null) {
        const y = element.getBoundingClientRect().top + window.scrollY
        window.scroll({
          top: y - 90,
          behavior: 'smooth',
        })
      }
    } catch (e) {
      console.log('error scrolling document: ', e)
    }
  }

  useEffect(() => {
    if (location.hash && location.hash !== locationHash) {
      setLocationHash(location.hash)
      scrollToSection(location.hash)
    }
  }, [location])

  return (
    <nav
      role='navigation'
      aria-label='main'
      className={`${styles.navbar} ${
        position > 70 || location.pathname.includes('/app/courses') ? styles.shadow : ''
      } ${globals['p-desktop']} ${globals['a-center']} ${globals.flex} ${globals['s-b']}`}
    >
      <div
        onClick={() => {
          setMobileActive(!mobileActive)
        }}
        className={styles.burger}
      >
        <IconRepository.BurgerIcon size='32' fillColor='var(--ROYALTY)' />
      </div>
      <div className={`${globals.flex} ${globals['a-center']} ${globals['gap-48']}`}>
        <Link to={appConstants.routes.landingPageRef}>
          <span className={styles.logo}>
            <IconRepository.LogoIcon width='180' />
          </span>
        </Link>
        <ul
          className={`${styles.menu} ${mobileActive ? styles.show : ''} ${globals.flex} ${
            globals['a-center']
          } ${globals['gap-8']}`}
        >
          <li>
            <Link
              onClick={() => {
                scrollToSection(appConstants.routes.landingPageRef)
              }}
              className={`${
                location.pathname === appConstants.routes.landingPageRef ? styles.active : ''
              }`}
              to={appConstants.sectionIds.home.academy}
            >
              <IconRepository.SettingsIcon fillColor='var(--WHITE_COLOR)' size='18' />
              {t('Home', { ns: ['main', 'home'] })}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                scrollToSection(appConstants.sectionIds.home.academy)
              }}
              className={`${
                location.hash === appConstants.sectionIds.home.academy ? styles.active : ''
              }`}
              to={appConstants.sectionIds.home.academy}
            >
              <IconRepository.EnrolledIcon fillColor='var(--WHITE_COLOR)' size='18' />
              {t('Artists', { ns: ['main', 'home'] })}
            </Link>
          </li>
          <li>
            <Link
              className={`${
                location.pathname.includes(appConstants.routes.teamRef) ? styles.active : ''
              }`}
              to={appConstants.routes.teamRef}
            >
              <IconRepository.VerifiedIcon
                strokeWidth='1.5'
                fillColor='var(--WHITE_COLOR)'
                size='18'
              />
              {t('Popular Songs', { ns: ['main', 'home'] })}
            </Link>
          </li>
          <li>
            <Link
              className={`${
                location.pathname.includes(appConstants.routes.teamRef) ? styles.active : ''
              }`}
              to={appConstants.routes.teamRef}
            >
              <IconRepository.VerifiedIcon
                strokeWidth='1.5'
                fillColor='var(--WHITE_COLOR)'
                size='18'
              />
              {t('Genres', { ns: ['main', 'home'] })}
            </Link>
          </li>
          <li>
            <Link
              className={`${
                location.pathname.includes(appConstants.routes.teamRef) ? styles.active : ''
              }`}
              to={appConstants.routes.teamRef}
            >
              <IconRepository.VerifiedIcon
                strokeWidth='1.5'
                fillColor='var(--WHITE_COLOR)'
                size='18'
              />
              {t('Newest', { ns: ['main', 'home'] })}
            </Link>
          </li>
        </ul>
      </div>
      {loginState?.tokens !== null && loginState?.tokens.user !== undefined ? (
        <Link to={appConstants.routes.dashboard.refLink}>
          <div
            style={{
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              overflow: 'hidden',
            }}
          >
            <img
              src={
                loginState.tokens?.user?.profileImage
                  ? loginState.tokens?.user?.profileImage?.httpPath
                  : ImageAssets.MaleAvatar
              }
              width='100%'
              height='100%'
              alt={loginState.tokens?.user?.firstName}
            />
          </div>
        </Link>
      ) : (
        <div
          className={`${styles.button__group} ${globals.flex} ${globals['center-items']} ${globals['gap-24']}`}
        >
          <Link className={styles.hide} to='/welcome'>
            {t('Create an Account', { ns: ['main', 'home'] })}
          </Link>
          <Link to='/auth/login'>
            <ButtonAtom
              className={styles.btn}
              label={t('Login', { ns: ['main', 'home'] })}
              buttonType={ButtonTypes.PRIMARY}
              fontSize={width < 800 ? '1.6rem' : '1.8rem'}
              buttonLook={ButtonLooks.OUTLINED}
            />
          </Link>
        </div>
      )}
    </nav>
  )
}

export default NavbarOrganism
