import globals from '../../../globals.module.css'
import styles from './translator.module.css'

import { IconRepository } from '../../../repository/icons.repository'

type Props = {
  action: () => void
}

const TranslatorMolecule = ({ action }: Props) => {
  return (
    <div onClick={action} className={styles.wrapper}>
      <div className={`${styles.translator} ${globals.flex} ${globals['center-items']}`}>
        <IconRepository.GlobeIcon />
      </div>
    </div>
  )
}

export default TranslatorMolecule
