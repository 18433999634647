import { IconType } from '../types/icons.type'

const PauseVideoIcon = ({ size = '24', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='6' y='5' width='4' height='14' rx='1' stroke={fillColor} strokeLinecap='round' />
      <rect x='14' y='5' width='4' height='14' rx='1' stroke={fillColor} strokeLinecap='round' />
    </svg>
  )
}

export default PauseVideoIcon
