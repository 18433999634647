import { Fragment, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { appConstants } from '../../app/constants'
import { LocalStorageService } from '../../services/storage/localstorage.service'

const localStorage = new LocalStorageService()

type Props = {
  children: React.ReactNode
}

const Internalization: React.FC<Props> = ({ children }) => {
  const { pathname, search } = window.location
  const navigate = useNavigate()
  const { lang } = useParams()
  const { i18n } = useTranslation(['home', 'main'])

  const supportedLangs = Object.keys(appConstants.supportedLangs)

  const filterPathName = (paths: string[]) => {
    const pathTemp: string[] = []

    paths.map((path) => {
      if (!supportedLangs.includes(path) && lang !== path) pathTemp.push(path)
    })

    return pathTemp
  }

  const generateRedirectLink = (lang: string, pathName: string[], search: string) => {
    return `/${lang}/${pathName.join('/')}${search}`
  }

  const languageLocalConfig = useCallback(async (local: string) => {
    localStorage.storeAppLang(local)
    await i18n.changeLanguage(local)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (lang !== null && lang !== undefined) {
      if (!supportedLangs.includes(lang)) {
        navigate(`/${filterPathName(pathname.substring(1).split('/')).join('/')}${search}`)
      } else {
        languageLocalConfig(lang)

        navigate(
          generateRedirectLink(lang, filterPathName(pathname.substring(1).split('/')), search),
        )
      }
    }
  }, [window.location])

  return <Fragment>{children}</Fragment>
}

export default Internalization
