import { useEffect, useRef, useState } from 'react'
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineLock,
  AiOutlineMail,
  AiOutlineUser,
} from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'

import styles from '../auth.module.css'

import axios, { isAxiosError } from 'axios'
import { appConstants } from '../../../app/constants'
import {
  NotificationTitleType,
  showNotification,
} from '../../../app/features/notifcation/notification.slice'
import ButtonAtom from '../../../components/atoms/button/Button.atom'
import SelectMolecule from '../../../components/molecules/select/Select.molecule'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import { IconRepository } from '../../../repository/icons.repository'
import { ButtonTypes } from '../../../types/enum.type'

export const accountTypes = ['User', 'Musician', 'Manager']
export const accountTypesObj = {
  user: 'User',
  musician: 'Musician',
  manager: 'Manager',
}

const RegisterPage = (): JSX.Element => {
  const firstName = useRef<HTMLInputElement | null>(null)
  const lastName = useRef<HTMLInputElement | null>(null)
  const email = useRef<HTMLInputElement | null>(null)
  const password = useRef<HTMLInputElement | null>(null)
  const confirmPassword = useRef<HTMLInputElement | null>(null)
  const [accountType, setAccountType] = useState('User')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const newAuthState = useAppSelector((state) => state.newAuthSlice)

  const [passwordToggle, setPasswordToggle] = useState(false)
  const dispatch = useAppDispatch()

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault()

    if (firstName.current?.value.length === 0) {
      dispatch(
        showNotification({
          message: 'Your first name is required.',
          title: NotificationTitleType.ERROR,
        }),
      )
      return
    }
    if (lastName.current?.value.length === 0) {
      dispatch(
        showNotification({
          message: 'Your last name is required.',
          title: NotificationTitleType.ERROR,
        }),
      )
      return
    }
    if (email.current?.value.length === 0) {
      dispatch(
        showNotification({
          message: 'Your email is required.',
          title: NotificationTitleType.ERROR,
        }),
      )
      return
    }
    if (password.current?.value.length === 0) {
      dispatch(
        showNotification({
          message: 'Please create a password.',
          title: NotificationTitleType.ERROR,
        }),
      )
      return
    }
    if (confirmPassword.current?.value.length === 0) {
      dispatch(
        showNotification({
          message: 'Please confirm your password.',
          title: NotificationTitleType.ERROR,
        }),
      )
      return
    }
    if (confirmPassword.current?.value !== password.current?.value) {
      dispatch(
        showNotification({
          message: 'Passwords must match.',
          title: NotificationTitleType.ERROR,
        }),
      )
      return
    }

    try {
      setLoading(true)
      const body = {
        firstName: firstName.current?.value as string,
        lastName: lastName.current?.value as string,
        accountType: accountType,
        email: email.current?.value as string,
        password: password.current?.value as string,
      }

      await axios
        .post('http://localhost:8000/api/auth/register', body, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          dispatch(
            showNotification({
              message: 'Success! Please check your email to verify your account.',
              title: NotificationTitleType.SUCCESS,
            }),
          )

          navigate(`${appConstants.routes.auth.verifyEmail}?email=${email.current?.value}`)
        })
    } catch (e) {
      if (isAxiosError(e) && e.response?.data) {
        dispatch(
          showNotification({
            message: e.response.data.message,
            title: NotificationTitleType.ERROR,
          }),
        )
        return
      }

      dispatch(
        showNotification({
          message: 'Error! Please ensure you have internet connection and have filled every field.',
          title: NotificationTitleType.ERROR,
        }),
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (newAuthState?.loginState?.tokens?.user != null) {
      navigate('/app/dashboard')
    }
  }, [newAuthState])

  return (
    <div className={styles.auth}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='1920'
        height='793.227'
        viewBox='0 0 1920 793.227'
        className={styles.svg}
      >
        <g id='Group_3' data-name='Group 3' transform='translate(0 -0.098)'>
          <path
            id='Path_1'
            data-name='Path 1'
            d='M96.749,0H884.56L801.632,12.658H0Z'
            transform='translate(1084.456 781.429) rotate(-20)'
            fill='#f51767'
          />
          <path
            id='Path_3'
            data-name='Path 3'
            d='M166.333,0H1520.762L1378.191,12.658H0Z'
            transform='translate(1424.29 11.915) rotate(159)'
            fill='#f51767'
          />
          <path
            id='Path_2'
            data-name='Path 2'
            d='M26.464,0,563.028,1.074,544.2,11.812,0,11.954Z'
            transform='translate(1037.387 773.767) rotate(-20)'
            fill='#5237aa'
          />
        </g>
      </svg>
      <div className={`${styles.auth__form} ${styles.diff}`}>
        <div className={styles.captions}>
          <Link to={'/'}>
            <IconRepository.LogoIcon width='250' />
          </Link>
          <h4>Welcome to Music Loop</h4>
          <h4>We are thrilled and excited to have you here.</h4>
          <h4>🎉</h4>
        </div>
        <form className={styles.form}>
          <h2>Create an Account</h2>

          <div className={styles.grouped}>
            <div className={styles.form__control}>
              <div className={styles.label}>
                <label htmlFor='firstName'>First Name</label>
              </div>
              <div className={styles.input__arena}>
                <div className={styles.icon__left}>
                  <AiOutlineUser size={17} />
                </div>
                <input type='text' ref={firstName} placeholder='First Name' name='firstname' />
              </div>
            </div>

            <div className={styles.form__control}>
              <div className={styles.label}>
                <label htmlFor='lastName'>Last Name</label>
              </div>
              <div className={styles.input__arena}>
                <div className={styles.icon__left}>
                  <AiOutlineUser size={17} />
                </div>
                <input type='text' ref={lastName} placeholder='Last Name' name='lastName' />
              </div>
            </div>
          </div>

          <div className={styles.form__control}>
            <div className={styles.label}>
              <label htmlFor='email'>Email</label>
            </div>
            <div className={styles.input__arena}>
              <div className={styles.icon__left}>
                <AiOutlineMail size={17} />
              </div>
              <input type='text' ref={email} placeholder='Email' name='email' />
            </div>
          </div>

          <div className={styles.form__control}>
            <div className={styles.label}>
              <label htmlFor='email'></label>
            </div>
            <div className={styles.select} style={{ width: '100%' }}>
              <SelectMolecule
                height='100px'
                selected={accountType}
                onSelect={(data) => {
                  setAccountType(data)
                }}
                list={accountTypes}
              />
            </div>
          </div>

          <div className={styles.grouped}>
            <div className={styles.form__control}>
              <div className={styles.label}>
                <label htmlFor='password'>Password</label>
              </div>
              <div className={styles.input__arena}>
                <div className={styles.icon__left}>
                  <AiOutlineLock size={17} />
                </div>
                <input
                  type={passwordToggle ? 'text' : 'password'}
                  ref={password}
                  placeholder='Password'
                  name='password'
                />
                <div className={styles.icon__right}>
                  {!passwordToggle ? (
                    <AiOutlineEyeInvisible
                      onClick={() => setPasswordToggle(true)}
                      style={{ cursor: 'pointer' }}
                      size={17}
                    />
                  ) : (
                    <AiOutlineEye
                      onClick={() => setPasswordToggle(false)}
                      style={{ cursor: 'pointer' }}
                      size={17}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.form__control}>
              <div className={styles.label}>
                <label htmlFor='confirmPassword'>Confirm Password</label>
              </div>
              <div className={styles.input__arena}>
                <div className={styles.icon__left}>
                  <AiOutlineLock size={17} />
                </div>
                <input
                  type={passwordToggle ? 'text' : 'password'}
                  ref={confirmPassword}
                  placeholder='Confirm Password'
                  name='confirmPassword'
                />
                <div className={styles.icon__right}>
                  {!passwordToggle ? (
                    <AiOutlineEyeInvisible
                      onClick={() => setPasswordToggle(true)}
                      style={{ cursor: 'pointer' }}
                      size={17}
                    />
                  ) : (
                    <AiOutlineEye
                      onClick={() => setPasswordToggle(false)}
                      style={{ cursor: 'pointer' }}
                      size={17}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: '4rem' }}>
            <ButtonAtom
              buttonType={ButtonTypes.PRIMARY}
              label='Create an Account'
              fontSize='1.8rem'
              rounded={false}
              onClick={handleSubmit}
              padding='10px 24px'
              height='56px'
              loading={loading}
            />
          </div>
          <h4>
            Already have an account? <Link to={appConstants.routes.auth.loginRefLink}>Login</Link>
          </h4>
        </form>
      </div>
    </div>
  )
}

export default RegisterPage
