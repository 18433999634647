import { useEffect, useState } from 'react'

import globals from '../../../../globals.module.css'
import styles from './events.module.css'

import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { ButtonTypes } from '../../../../types/enum.type'

const EventsView = () => {
  const [tickets, setTickets] = useState([])

  useEffect(() => {
    setTickets([])
  }, [])

  return (
    <section className={`${styles.index} ${globals['full-width']}`}>
      <div className={`${styles.headline}`}>
        <h2>Tickets and Events</h2>
      </div>

      {tickets.length === 0 ? (
        <div className={`${styles.no__ticket}`}>
          <p>No tickets or events yet</p>
          <ButtonAtom label='Create New Events' buttonType={ButtonTypes.PRIMARY} />
        </div>
      ) : (
        ''
      )}
    </section>
  )
}

export default EventsView
