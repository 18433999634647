import React from 'react'

import styles from '../simple-carousel/simplecarousel.module.css'

import ArrowIcon from '../../../icons/Arrow.icon'

type PaginationProps = {
  handlePrev: () => void
  index: number
  length: number
  handleNext: () => void
  handleIndex: (index?: number) => void
}

const Pagination2: React.FC<PaginationProps> = ({
  handlePrev,
  handleNext,
  index,
  handleIndex,
  length,
}) => {
  return (
    <div className={styles.pagination}>
      <div className={styles.pagination__wrapper}>
        <button onClick={() => handlePrev()} className={styles.pagination__button}>
          <ArrowIcon
            fillColor='#fff'
            className={`${styles.pagination__button__icon} ${styles.rotate}`}
          />
        </button>
        <div className={styles.pagination__indicator__wrapper}>
          <button
            onClick={() => handleIndex(1)}
            type='button'
            className={`${styles.pagination__item} ${
              index === 0 ? styles.pagination__item__active : ''
            }`}
          >
            1
          </button>
          {index > 2 && <div className={styles.pagination__separator}>...</div>}
          {index === length - 1 && length > 3 && (
            <button
              onClick={() => handleIndex(index - 1)}
              type='button'
              className={styles.pagination__item}
            >
              {index - 1}
            </button>
          )}
          {index > 1 && (
            <button
              onClick={() => handleIndex(index)}
              type='button'
              className={styles.pagination__item}
            >
              {index}
            </button>
          )}
          {index !== 0 && index !== length - 1 && (
            <button
              onClick={() => handleIndex(index + 1)}
              type='button'
              className={`${styles.pagination__item} ${styles.pagination__item__active}`}
            >
              {index + 1}
            </button>
          )}

          {index < length - 2 && (
            <button
              onClick={() => handleIndex(index + 2)}
              type='button'
              className={styles.pagination__item}
            >
              {index + 2}
            </button>
          )}
          {index === 0 && length > 3 && (
            <button
              onClick={() => handleIndex(index + 3)}
              type='button'
              className={styles.pagination__item}
            >
              {index + 3}
            </button>
          )}
          {index < length - 3 && <div className={styles.pagination__separator}>...</div>}
          <button
            onClick={() => handleIndex(length)}
            type='button'
            className={`${styles.pagination__item} ${
              index === length - 1 ? styles.pagination__item__active : ''
            }`}
          >
            {length}
          </button>
        </div>
        <button onClick={() => handleNext()} className={styles.pagination__button}>
          <ArrowIcon fillColor='#fff' className={`${styles.pagination__button__icon}`} />
        </button>
      </div>
    </div>
  )
}

export default Pagination2
