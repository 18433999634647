import { IconType } from '../types/icons.type'

const PlayVideoIcon = ({ size = '24', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.2111 11.1056L7.73666 6.86833C6.93877 6.46939 6 7.04958 6 7.94164V16.0584C6 16.9504 6.93877 17.5306 7.73666 17.1317L16.2111 12.8944C16.9482 12.5259 16.9482 11.4741 16.2111 11.1056Z'
        stroke={fillColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default PlayVideoIcon
