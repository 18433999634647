import AddressIcon from '../icons/Address.icon'
import AllVolumeIcon from '../icons/AllVolume.icon'
import ArrowIcon from '../icons/Arrow.icon'
import BadgeBagIcon from '../icons/BadgeBag.icon'
import BurgerIcon from '../icons/Burger.icon'
import CVBuilderIcon from '../icons/CVBuilder.icon'
import CameraIcon from '../icons/Camera.icon'
import CaretDownIcon from '../icons/CaretDown.icon'
import CheckedIcon from '../icons/Checked.icon'
import CloseIcon from '../icons/Close.icon'
import CloseQuoteIcon from '../icons/CloseQuote.icon'
import CloudFailureIcon from '../icons/CloudFailure.icon'
import CloudSuccessIcon from '../icons/CloudSuccess.icon'
import ComingSoonIcon from '../icons/ComingSoon.icon'
import DarkModeIcon from '../icons/DarkMode.icon'
import DeleteIcon from '../icons/Delete.icon'
import EnrolledIcon from '../icons/Enrolled.icon'
import EyeClosedIcon from '../icons/EyeClosed.icon'
import EyeDefaultIcon from '../icons/EyeDefault.icon'
import FieldIcon from '../icons/Field.icon'
import FlagIcon from '../icons/Flag.icon'
import GithubIcon from '../icons/Github.icon'
import GlobeIcon from '../icons/Globe.icon'
import GoogleIcon from '../icons/Google.icon'
import JobtitleIcon from '../icons/Jobtitle.icon'
import LightModeIcon from '../icons/LightMode.icon'
import LinkedinIcon from '../icons/Linkedin.icon'
import LocationIcon from '../icons/Location.icon'
import LockIcon from '../icons/Lock.icon'
import LogoIcon from '../icons/Logo.icon'
import MailIcon from '../icons/Mail.icon'
import MuteVolumeIcon from '../icons/MuteVolume.icon'
import NotificationIcon from '../icons/Notification.icon'
import OpenQuoteIcon from '../icons/OpenQuote.icon'
import PauseVideoIcon from '../icons/PauseVideo.icon'
import PhoneIcon from '../icons/Phone.icon'
import PlayVideoIcon from '../icons/PlayVideo.icon'
import SettingsIcon from '../icons/Settings.icon'
import SkillBadgeIcon from '../icons/SkillBadge.icon'
import SuggestionBoxIcon from '../icons/SuggestionBox.icon'
import TwitterIcon from '../icons/Twitter.icon'
import UserIcon from '../icons/User.icon'
import VerifiedIcon from '../icons/Verified.icon'
import WarningIcon from '../icons/Warning.icon'

export const IconRepository = {
  PauseVideoIcon,
  PlayVideoIcon,
  LinkedinIcon,
  GithubIcon,
  TwitterIcon,
  ComingSoonIcon,
  CloseIcon,
  LightModeIcon,
  DarkModeIcon,
  CloudFailureIcon,
  CloudSuccessIcon,
  DeleteIcon,
  CameraIcon,
  WarningIcon,
  BadgeBagIcon,
  SuggestionBoxIcon,
  EnrolledIcon,
  FieldIcon,
  JobtitleIcon,
  NotificationIcon,
  SettingsIcon,
  GlobeIcon,
  SkillBadgeIcon,
  CheckedIcon,
  CaretDownIcon,
  LogoIcon,
  GoogleIcon,
  ArrowIcon,
  CloseQuoteIcon,
  OpenQuoteIcon,
  MailIcon,
  LockIcon,
  EyeClosedIcon,
  EyeDefaultIcon,
  UserIcon,
  PhoneIcon,
  BurgerIcon,
  AddressIcon,
  FlagIcon,
  LocationIcon,
  CVBuilderIcon,
  VerifiedIcon,
  AllVolumeIcon,
  MuteVolumeIcon,
}
