/* eslint-disable */

export const languageData = [
  {
    name: 'English',
    id: 'en',
  },
  {
    name: 'French',
    id: 'fr',
  },
  ,
  {
    name: 'German',
    id: 'de',
  },
]
