import { createSlice } from '@reduxjs/toolkit'

import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../../types/api.types'
import { LocalStorageService } from '../../../../services/storage/localstorage.service'
import { PhoneNumberType, getPhoneNumbersThunk } from '../thunks/phone-number.thunk'

const localStorageService = new LocalStorageService()

export type PhoneNumberState = {
  requestResponse: ApiRequestDataType<PhoneNumberType[]>
}

export const initialState: PhoneNumberState = {
  requestResponse: {
    status:
      localStorageService.getStoredPhoneCodes() !== null &&
      localStorageService.getStoredPhoneCodes() !== undefined
        ? ApiRequestStatus.FULFILLED
        : ApiRequestStatus.IDLE,
    data: localStorageService.getStoredPhoneCodes(),
  },
}

const phoneNumberSlice = createSlice({
  name: 'phoneNumberSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPhoneNumbersThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
      })
      .addCase(getPhoneNumbersThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload

        localStorageService.storePhoneCodes(action.payload)
      })
      .addCase(getPhoneNumbersThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as unknown as StoredErrorResponseType
      })
  },
})

export default phoneNumberSlice.reducer
