import { useState } from 'react'

import globals from '../../../../globals.module.css'
import styles from './index.module.css'

import { AiOutlineEdit, AiOutlineUser } from 'react-icons/ai'
import { ImageAssets } from '../../../../app/assets'
import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import AlbumsMolecule from '../../../../components/molecules/albums/Albums.molecule'
import AudioCardComponent from '../../../../components/molecules/audio-card/AudioCard.molecule'
import Pagination2 from '../../../../components/organisms/pagination/Pagination.organism'
import SimpleCarousel from '../../../../components/organisms/simple-carousel/SimpleCarousel.organism'
import { useAppSelector } from '../../../../lib/hooks'
import UseWindowSize from '../../../../lib/hooks/get-window-size.hook'
import { popularSongs } from '../../../../repository/data.repository'
import { IconRepository } from '../../../../repository/icons.repository'
import { ButtonTypes, IconDirection } from '../../../../types/enum.type'
import { accountTypesObj } from '../../../auth/register/Register.page'

const album = [
  {
    img: '/images/albums/arts1.webp',
    title: 'Album 1',
  },
  {
    img: '/images/albums/arts2.webp',
    title: 'Album 2',
  },
  {
    img: '/images/albums/arts3.webp',
    title: 'Album 3',
  },
  {
    img: '/images/albums/arts4.webp',
    title: 'Album 4',
  },
  {
    img: '/images/albums/arts5.webp',
    title: 'Album 5',
  },
  {
    img: '/images/albums/arts6.webp',
    title: 'Album 6',
  },
  {
    img: '/images/albums/arts7.webp',
    title: 'Album 7',
  },
  {
    img: '/images/albums/arts8.webp',
    title: 'Album 8',
  },
]

const IndexView = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0 as number)
  const newAuthState = useAppSelector((state) => state.newAuthSlice)
  const { loginState } = newAuthState
  const [upload, setUpload] = useState(false)
  const [loading, setLoading] = useState(false)

  const [title, setTitle] = useState('')
  const [image, setImage] = useState<File>()
  const [video, setVideo] = useState<string | ArrayBuffer | null>(null)

  console.log(setLoading)

  const handlePrev2 = () => {
    if (activeIndex !== 0) {
      setActiveIndex(activeIndex - 1)
    }
  }

  const handleNext2 = () => {
    if (activeIndex < Math.ceil(popularSongs.length / numberOfCardDisplay()) - 1) {
      setActiveIndex(activeIndex + 1)
    }
  }

  const handleIndex = (index = 0) => {
    setActiveIndex(index - 1)
  }

  const { width } = UseWindowSize()

  const numberOfCardDisplay = () => {
    if (width <= 490) {
      return 1
    } else if (width <= 844) {
      return 2
    } else if (width <= 1233) {
      return 3
    } else {
      return 4
    }
  }

  return (
    <section className={`${styles.index} ${globals['full-width']}`}>
      <div className={`${styles.headline}`}>
        <h2>My Songs</h2>
      </div>
      <>
        {upload === true ? (
          <div className={`${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}>
            <div>
              <div className={`${styles.image}`}>
                <img
                  src={
                    image
                      ? URL.createObjectURL(image)
                      : loginState?.tokens.user.profileImage
                      ? loginState.tokens.user.profileImage.httpPath
                      : ImageAssets.MaleAvatar
                  }
                  width='100%'
                  height='100%'
                  alt='user'
                />
                <div className={styles.edit}>
                  <AiOutlineEdit fontSize='3rem' />
                  <input
                    onChange={(e) => {
                      if (e.target.files) {
                        setImage(e.target.files[0])
                      }
                    }}
                    accept='image/*'
                    type='file'
                    name='image'
                    id='image'
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  width: '100%',
                  maxWidth: '100%',
                }}
                className={`${styles.image}`}
              >
                {video ? <video width={'100%'} controls src={video as string}></video> : ''}
                <div className={styles.edit}>
                  <AiOutlineEdit fontSize='3rem' />
                  <input
                    onChange={(e) => {
                      if (e.target.files) {
                        const reader = new FileReader()
                        const selectedFile = e.target.files[0]
                        if (selectedFile) {
                          reader.readAsDataURL(selectedFile)
                        }

                        reader.onload = (readerEvent) => {
                          if (readerEvent.target) {
                            const result = readerEvent.target.result as string
                            setVideo(result)
                          }
                        }
                      }
                    }}
                    accept='video/*'
                    type='file'
                    name='video'
                    id='video'
                  />
                </div>
              </div>
            </div>
            <div
              className={`${styles.form} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
            >
              <div className={styles.form__control}>
                <div className={styles.label}>
                  <label htmlFor='title'>Title</label>
                </div>
                <div className={styles.input__arena}>
                  <div className={styles.icon__left}>
                    <AiOutlineUser size={17} />
                  </div>
                  <input
                    type='text'
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                    value={title}
                    placeholder='Title'
                    name='title'
                  />
                </div>
              </div>

              <div style={{ marginTop: '1rem' }}>
                <ButtonAtom
                  buttonType={ButtonTypes.PRIMARY}
                  label='Upload Track'
                  fontSize='1.6rem'
                  rounded={false}
                  onClick={() => {
                    console.log('====================================')
                    console.log('Submitted')
                    console.log('====================================')
                  }}
                  padding='10px 24px'
                  height='56px'
                  loading={loading}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <SimpleCarousel items={popularSongs.length} activeIndex={activeIndex}>
              <div className={`${styles.grid}`}>
                {popularSongs
                  .slice(
                    activeIndex * numberOfCardDisplay(),
                    numberOfCardDisplay() * (activeIndex + 1),
                  )
                  .map((data, index) => {
                    return (
                      <div key={index} style={{ width: '100%' }}>
                        <AudioCardComponent
                          image={data.image}
                          artistName={data.artistName}
                          songName={data.songName}
                          index={index}
                        />
                      </div>
                    )
                  })}
              </div>
            </SimpleCarousel>
            <div className={styles.pagination}>
              <Pagination2
                handleIndex={handleIndex}
                handleNext={handleNext2}
                handlePrev={handlePrev2}
                length={Math.ceil(popularSongs.length / numberOfCardDisplay())}
                index={activeIndex}
              />
            </div>
            <div className={styles.btn}>
              <div></div>
              <div style={{ width: 'fit-content' }}>
                <ButtonAtom
                  buttonType={ButtonTypes.PRIMARY}
                  iconDir={IconDirection.LEFT}
                  icon={<IconRepository.CloudSuccessIcon size='20' fillColor='#fff' />}
                  // buttonLook={ButtonLooks.OUTLINE
                  onClick={() => {
                    setUpload(true)
                  }}
                  label='Upload new song'
                  fontSize='1.4rem'
                  rounded={false}
                  padding='10px 24px'
                  height='6px'
                />
              </div>
            </div>
            {accountTypesObj.musician === loginState?.tokens.user.accountType ? (
              <div>
                <div className={`${styles.headline}`}>
                  <h2>My Albums</h2>
                </div>
                <div className={styles.wrapper}>
                  {album.map((data, index) => (
                    <AlbumsMolecule key={index} img={data.img} title={data.title} />
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </>
    </section>
  )
}

export default IndexView
