/* eslint-disable */

import { translationsData } from '../util'

export const testimonyData = [
  {
    id: 0,
    name: "N'nouka Stephen",
    testimony:
      'I was looking for a way to upskill and advance my career, and I found CertiUp to be the perfect solution. The platform offers a wide range of certifications in different industries, and I was able to find one that was a perfect fit for my interests and goals. I also had the opportunity to be paired with a mentor who was an expert in my field, and they provided me with invaluable guidance and support. I am confident that the skills and knowledge I gained through CertiUp will help me to achieve my career goals.',
  },
  {
    id: 1,
    name: 'Nchia Emmanuela Ekei',
    testimony:
      "I was new to the workforce and didn't know anyone in my field. I was feeling lost and confused, and I didn't know where to start. Then I found CertiUp. The platform helped me to connect with other professionals in my field, and I was able to find a mentor who was willing to help me navigate my career. I am so grateful for the support I received from CertiUp. It helped me to find my footing and start my career on the right foot.",
  },
  {
    id: 2,
    name: 'Njoh Noh Prince Junior',
    testimony:
      "I had been working in the same industry for years, and I was feeling stagnant. I wanted to learn new skills and make a change, but I didn't know where to start. Then I found CertiUp. The platform offered a variety of courses and programs that helped me to learn new skills and stay up-to-date on the latest trends in my industry. I also had the opportunity to be paired with a mentor who helped me to develop my career goals and make a plan to achieve them. I am so glad I found CertiUp. It helped me to take my career to the next level.",
  },
]

export const certifications = [
  {
    name: 'HTML5 and CSS3',
    duration: '1 Month',
    image: '/images/svgs/cert10.svg',
    price: '25,000 FCFA',
    description:
      'Learn modern HTML5, CSS3 and web design by building a stunning website for your portfolio! Includes flexbox, CSS Grid and moves you from zero to hero.',
  },
  {
    name: 'JavaScript',
    duration: '2 Months',
    image: '/images/svgs/cert9.svg',
    price: '30,000 FCFA',
    description:
      'The modern JavaScript certification for everyone! Master JavaScript with projects, challenges and theory. Many courses in one!',
  },
  {
    name: 'TypeScript',
    duration: '1 Months',
    image: '/images/svgs/cert12.svg',
    price: '25,000 FCFA',
    description:
      'Learn how to optimize your code, reduce bugs with this masterpiece TypeScript certification. Learn by building projects, challenges and theory.',
  },
  {
    name: 'React.js',
    duration: '3 Months',
    image: '/images/svgs/cert2.svg',
    price: '50,000 FCFA',
    description:
      'Dive in and learn React.js from scratch! Learn React, Hooks, Redux, React Router, Next.js, Best Practices and way more!',
  },
]

export const teamData = [
  {
    image: '/images/team/nouks.webp',
    name: "N'nouka A'Issah Stephen",
    skill: 'Senior Software Engineer',
    linkedin: 'https://www.linkedin.com/in/n-nouka-stephen-issah/',
    github: 'https://github.com/Nnouka',
    more: [
      translationsData[
        'Stephen studied physics and computer science in his home country, Cameroon. Being bilingual, he was able to work for companies both in Cameroon and the United States before moving to Paris. He has <b>6 years</b> of relevant and <b>verifiable work experience</b>. Stephen is very interested in learning and advancing his career in web development, he has passed and obtained various certifications in <b>React.JS</b>, <b>Java</b> and <b>AWS</b>. He is also interested in <b>cloud devops with GCP</b>.'
      ],
      translationsData[
        "Stephen is currently a student of computer science at the university of london, with focus on mobile web development. He wants to model developer career success through <a target='_blank' rel='noreferrer' href='https://www.certiup.com'>certiup.com</a> and allow other young <b>talents</b> to certify their success and work history, by earning industry <b>trusted certifications</b> and working with <b>clear professional objectives</b>."
      ],
    ],
  },
  {
    image: '/images/team/emma.webp',
    name: 'Nchia Emmanuela Ekei',
    skill: 'Full Stack Engineer',
    linkedin: 'https://www.linkedin.com/in/nchia-emmanuela-854199230/',
    github: 'https://github.com/Nchia-Emmanuela',
    more: [
      translationsData[
        'Nchia Emmanuela, a full-stack developer with <b>3 years of experience</b> working for both local and remote companies, stands before you with a mission close to her heart - <b>the empowerment of women in the field of technology.</b>'
      ],
      translationsData[
        'Recently, we have witnessed remarkable progress in bridging the gender gap in various industries. However, the tech industry still lags behind. It is disheartening to see the <b>underrepresentation of women</b> in this field, as their <b>unique perspectives</b> is essential for <b>driving innovation </b> and <b>shaping the future</b>.'
      ],
      translationsData[
        "Through <a target='_blank' rel='noreferrer' href='https://www.certiup.com'>certiup.com</a>, she actively <b>encourages young ladies</b> to explore careers relating to technology and engineering. By providing them with the necessary resources, mentorship, and role models, she strongly believes it will <b>ignite their passion</b> and <b>confidence</b> in pursuing tech careers."
      ],
    ],
  },
  {
    image: '/images/team/prince.webp',
    name: 'Njoh Noh Prince Junior',
    skill: 'Full Stack Engineer',
    linkedin: 'https://linkedin.com/in/njoh-noh-prince-junior-b93347201/',
    github: 'https://github.com/NjohPrince',
    more: [
      translationsData[
        'In the ever-evolving world of technology, passionate programmers play a crucial role in shaping the future. Prince Junior, a full-stack developer with <b>4 years of experience</b> and a lover of JavaScript/TypeScript tech stacks, stands out for his commitment to writing <b>robust</b>, <b>scalable</b>, and <b>maintainable</b> code. However, his impact extends beyond his coding prowess. He possesses a <b>deep desire</b> to <b>mentor and uplift</b> younger generations, helping them upskill in their respective domains or fields of interest.'
      ],
      translationsData[
        "Through <a target='_blank' rel='noreferrer' href='https://www.certiup.com'>certiup.com</a>, he inspires and motivates aspiring talents to certify their successes, work experience, and also earn badges of excellence. These badges are often awarded for exceptional performance or contributions, serving as a visual representation of their achievements."
      ],
    ],
  },
  {
    image: '/images/team/nobert.webp',
    name: 'Nobert Ndip Etta',
    skill: 'Backend Web Developer',
    linkedin: 'http://www.linkedin.com/in/nobert-etta',
    github: 'https://github.com/Suprememajor',
    more: [
      translationsData[
        "Nobert studied mathematics and physics at an advanced level. Later, he pursued and obtained a bachelor's degree in Electrical and Electronic Engineering, specializing in Telecommunications, from the University of Buea. As a <b>tech enthusiast</b>, he dedicated time to self-study and building a career in <b>web development</b> by taking courses outside of the university environment. He has accumulated over <b>2 years of experience</b> working as a <b>professional web developer</b> for companies both in Cameroon and Germany."
      ],
      translationsData[
        "Nobert is <b>enthusiastic</b> and <b>passionate</b> about using technology to provide solutions to everyday problems. Consequently, he is <b>committed</b> to continuously improving his skills and staying updated with relevant technologies. With <a target='_blank' rel='noreferrer' href='https://www.certiup.com'>certiup.com</a> he is excited about <b>mentoring</b> and <b>collaborating</b> with young talents to help them launch their careers in the tech industry."
      ],
    ],
  },
]

export const artistData = [
  {
    name: 'Nchia Emmanuela',
    image: '/images/artist/music1.webp',
  },
  {
    name: 'Jesus Boy',
    image: '/images/artist/music2.webp',
  },
  {
    name: 'Tenor +237',
    image: '/images/artist/music3.webp',
  },
  {
    name: 'Locko +237',
    image: '/images/artist/music4.webp',
  },
  {
    name: 'Salatiel +237',
    image: '/images/artist/music5.webp',
  },
  {
    name: 'Mercy Chinwoh',
    image: '/images/artist/music6.webp',
  },
  {
    name: 'Daphne +237',
    image: '/images/artist/music7.webp',
  },
  {
    name: 'Ella Cute',
    image: '/images/artist/music8.webp',
  },
  {
    name: 'Prince Hero',
    image: '/images/artist/music9.webp',
  },
  {
    name: 'Junior Styles',
    image: '/images/artist/music10.webp',
  },
  {
    name: 'Mercy Pride',
    image: '/images/artist/music11.webp',
  },
  {
    name: 'Yolanda Angel',
    image: '/images/artist/music12.webp',
  },
  {
    name: 'Yolanda Angel',
    image: '/images/artist/music12.webp',
  },
  {
    name: 'Yolanda Angel',
    image: '/images/artist/music12.webp',
  },
  {
    name: 'Yolanda Angel',
    image: '/images/artist/music12.webp',
  },
  {
    name: 'Yolanda Angel',
    image: '/images/artist/music12.webp',
  },
]

export const popularSongs = [
  {
    songName: 'Praise God',
    artistName: 'Yolanda Angel',
    image: '/images/albums/arts1.webp',
  },
  {
    songName: 'Song One',
    artistName: 'Jesus Boy',
    image: '/images/albums/arts2.webp',
  },
  {
    songName: 'Song Two',
    artistName: 'Daphne +237',
    image: '/images/albums/arts3.webp',
  },
  {
    songName: 'Song Three',
    artistName: 'Daphne +237',
    image: '/images/albums/arts4.webp',
  },
  {
    songName: 'Song Four',
    artistName: 'Yolanda Angel',
    image: '/images/albums/arts5.webp',
  },
  {
    songName: 'Song Five',
    artistName: 'Yolanda Angel',
    image: '/images/albums/arts6.webp',
  },
  {
    songName: 'Song Six',
    artistName: 'Daphne +237',
    image: '/images/albums/arts7.webp',
  },
  {
    songName: 'Song Seven',
    artistName: 'Salatiel +237',
    image: '/images/albums/arts8.webp',
  },
  {
    songName: 'Song Eight',
    artistName: 'Salatiel +237',
    image: '/images/albums/arts9.webp',
  },
  {
    songName: 'Song Nine',
    artistName: 'Salatiel +237',
    image: '/images/albums/arts10.webp',
  },
  {
    songName: 'Song Ten',
    artistName: 'Yolanda Angel',
    image: '/images/albums/arts11.webp',
  },
  {
    songName: 'Song Eleven',
    artistName: 'Salatiel +237',
    image: '/images/albums/arts12.webp',
  },
  {
    songName: 'Song Twelve',
    artistName: 'Yolanda Angel',
    image: '/images/albums/arts13.webp',
  },
  {
    songName: 'Praise God',
    artistName: 'Yolanda Angel',
    image: '/images/albums/arts1.webp',
  },
  {
    songName: 'Praise God',
    artistName: 'Yolanda Angel',
    image: '/images/albums/arts1.webp',
  },
  {
    songName: 'Praise God',
    artistName: 'Yolanda Angel',
    image: '/images/albums/arts1.webp',
  },
]
