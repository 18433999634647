// import storage from 'redux-persist/lib/storage'
// import { persistReducer } from 'redux-persist'

import loginSlice from '../features/auth/slices/login.slice'
import newAuthSlice from '../features/auth/slices/new-auth-slice'
import verifyEmailSlice from '../features/auth/slices/verify-email.slice'
import modalSlice from '../features/modal/modal.slice'
import notificationSlice from '../features/notifcation/notification.slice'
import phoneNumberSlice from '../features/phone-number/slices/phone-number.slice'
import scripts from '../features/scripts'

// const persistConfig = {
//   key: 'root',
//   storage,
// }

// const persistedReducer = persistReducer(persistConfig, courseSlice)

const combinedRootReducers = {
  phoneNumberSlice,
  notificationSlice,
  loginSlice,
  verifyEmailSlice,
  modalSlice,
  scripts,
  newAuthSlice,
}

export default combinedRootReducers
