/* eslint-disable */

import { ApiRequestErrorType, ApiVaidationRequestErrorType } from '../../types/api.types'
import { InternalServerError } from '../hooks/axios-instance'

/**
 *
 * @param ex
 * @returns
 */
export const getExceptionPayload = (
  ex: unknown,
):
  | ApiRequestErrorType
  | {
      message: string
      code: number
    } => {
  console.log({ ex })

  if ((ex as { code: string }).code === 'ERR_NETWORK') {
    return {
      message: 'Network Error. Please check your internet connection and try again.',
      code: -501,
    }
  }

  if (typeof ex !== 'object' || !ex) {
    return InternalServerError
  }

  const typeofException = ex as ApiRequestErrorType
  const typeOfExceptionRef = ex as ApiVaidationRequestErrorType

  if (
    ex.hasOwnProperty('response') &&
    typeOfExceptionRef.response.data.errors !== undefined &&
    typeof typeOfExceptionRef.response.status === 'number'
  ) {
    const obj = typeOfExceptionRef.response.data.errors

    if (Array.isArray(obj) && obj[0].hasOwnProperty('message')) {
      return {
        message: obj[0].message,
        code: typeofException.response.status,
      }
    }

    return {
      message: obj[Object.keys(obj)[0] as keyof typeof obj][0],
      code: typeofException.response.status,
    }
  }

  if (
    ex.hasOwnProperty('response') &&
    typeof typeofException.response.data.message === 'string' &&
    ex.hasOwnProperty('code') &&
    typeof typeofException.response.status === 'number'
  ) {
    return {
      message: typeofException.response.data.message,
      code: typeofException.response.status,
    }
  }

  return InternalServerError
}
