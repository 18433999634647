import { appConstants } from '../../app/constants'
import { PhoneNumberType } from '../../app/features/phone-number/thunks/phone-number.thunk'
import { UserResponse } from '../../types'

export function set(key: string, value: string) {
  try {
    window.localStorage.setItem(key, value)
  } catch {
    console.warn('Error reading from local storage')
  }
}

export function get(key: string) {
  try {
    const item = window.localStorage.getItem(key)
    return item
  } catch {
    return null
  }
}

export function setUserInfo(info: UserResponse) {
  set(appConstants.storageKeys.currentUser, JSON.stringify(info))
}

export function getUserInfo(): UserResponse | null {
  const info = get(appConstants.storageKeys.currentUser)
  if (info != null) {
    try {
      return JSON.parse(info)
    } catch (ignored) {
      /* empty */
    }
  }
  return null
}

export function clearUserInfo() {
  localStorage.removeItem(appConstants.storageKeys.currentUser)
}

export class LocalStorageService {
  public storePhoneCodes(phoneCodes: PhoneNumberType[]) {
    set(appConstants.storageKeys.phoneCodes, JSON.stringify(phoneCodes))
  }

  public getStoredPhoneCodes() {
    return JSON.parse(get(appConstants.storageKeys.phoneCodes) as string)
  }

  public storeAppLang(lang: string) {
    set(appConstants.storageKeys.appLang, lang)
  }

  public getAppLang() {
    const appLang = get(appConstants.storageKeys.appLang)
    return {
      appLang: appLang,
    }
  }
}
