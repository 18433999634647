import { lazy } from 'react'

import styles from './landing.module.css'

import AudioTemplate from '../../components/templates/home/audios/Audio.template'
import MusicTemplate from '../../components/templates/home/music/Music.template'
import GeneralLayout from '../../layouts/general/General.layout'
import Internalization from '../../layouts/internationalization/Internalization'

const HeroHomeTemplate = lazy(
  () => import('../../components/templates/home/hero/Hero.home.template'),
)

const LandingPage = () => {
  // useEffect(() => {
  //   ;(async () => {
  //     try {
  //       await axios
  //         .get('http://localhost:8000/api/auth/account-types', {
  //           withCredentials: true,
  //         })
  //         .then((response) => {
  //           console.log('====================================')
  //           console.log({ response: response.data })
  //           console.log('====================================')
  //         })
  //     } catch (e) {
  //       console.log('====================================')
  //       console.log({ e })
  //       console.log('====================================')
  //     }
  //   })()
  // }, [])

  return (
    <Internalization>
      <GeneralLayout>
        <div className={styles.landing__page}>
          <HeroHomeTemplate />
          <MusicTemplate />
          <AudioTemplate />
        </div>
      </GeneralLayout>
    </Internalization>
  )
}

// src/App.tsx
// import React, { useState } from 'react';
// import ReactPlayer from 'react-player';

// const App: React.FC = () => {
//   const [showVideo, setShowVideo] = useState(true);

//   const deleteVideo = () => {
//     const videoUrl = 'url_to_your_audio_or_video_file'; // Replace with the actual URL
//     // Add logic to delete the video and notify the service worker
//     setShowVideo(false);
//     // Trigger service worker to delete cached video
//     navigator.serviceWorker.controller.postMessage({ type: 'DELETE_VIDEO', url: videoUrl });
//   };

//   return (
//     <div>
//       <h1>Your PWA App</h1>
//       {showVideo && <ReactPlayer url="url_to_your_audio_or_video_file" controls />}
//       <button onClick={deleteVideo}>Delete Video</button>
//     </div>
//   );
// };

// export default App;

export default LandingPage
