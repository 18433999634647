import { useState } from 'react'

import globals from '../../../../globals.module.css'
import styles from './profile.module.css'

import axios, { isAxiosError } from 'axios'
import { AiOutlineEdit, AiOutlineMail, AiOutlinePhone, AiOutlineUser } from 'react-icons/ai'
import { ImageAssets } from '../../../../app/assets'
import { updateNewAuthState } from '../../../../app/features/auth/slices/new-auth-slice'
import {
  NotificationTitleType,
  showNotification,
} from '../../../../app/features/notifcation/notification.slice'
import ButtonAtom from '../../../../components/atoms/button/Button.atom'
import { GooglePlaceInput } from '../../../../components/molecules/google-places/GooglePlaceInput.component'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import { LoginResponseType, Place } from '../../../../types'
import { ButtonTypes } from '../../../../types/enum.type'

const ProfileView = () => {
  const newAuthState = useAppSelector((state) => state.newAuthSlice)
  const { loginState } = newAuthState

  const [firstName, setFirstName] = useState(loginState?.tokens?.user.firstName || '')
  const [lastName, setLastName] = useState(loginState?.tokens?.user.lastName || '')
  const [email, setEmail] = useState(loginState?.tokens?.user.email || '')
  const [contact, setContact] = useState(loginState?.tokens?.user.phoneNumber || '')
  const [handle, setHandle] = useState(loginState?.tokens?.user.handle || '')
  const [image, setImage] = useState<File>()

  const [location, setLocation] = useState<Partial<Place> | undefined>()
  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch()

  const fetchProfile = async () => {
    try {
      setLoading(true)

      await axios
        .get('http://localhost:8000/api/users/profile', {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${loginState?.tokens.accessToken}`,
          },
        })
        .then((response) => {
          const userInfoFromStorage = localStorage.getItem('loginResponse')
          const loginState: LoginResponseType = userInfoFromStorage
            ? JSON.parse(userInfoFromStorage)
            : null

          const { tokens } = loginState
          const newData = { tokens: { ...tokens, user: response.data.user } }

          localStorage.setItem('loginResponse', JSON.stringify(newData))

          dispatch(
            updateNewAuthState({
              data: newData,
            }),
          )

          dispatch(
            showNotification({
              message: 'Profile Information Updated.',
              title: NotificationTitleType.SUCCESS,
            }),
          )
        })
    } catch (e) {
      if (isAxiosError(e) && e.response?.data) {
        dispatch(
          showNotification({
            message: e.response.data.message,
            title: NotificationTitleType.ERROR,
          }),
        )
        return
      }

      dispatch(
        showNotification({
          message: 'Error! Please ensure you have internet connection and have filled every field.',
          title: NotificationTitleType.ERROR,
        }),
      )
    } finally {
      setLoading(false)
    }
  }

  const updateProfileImage = async () => {
    if (!image) {
      dispatch(
        showNotification({
          message: 'Please select an image.',
          title: NotificationTitleType.ERROR,
        }),
      )
      return
    }

    try {
      setLoading(true)
      const body = new FormData()
      body.append('image', image)

      await axios
        .patch('http://localhost:8000/api/users/update-profile-image', body, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${loginState?.tokens.accessToken}`,
          },
        })
        .then(() => {
          dispatch(
            showNotification({
              message: 'Your profile image has been updated.',
              title: NotificationTitleType.SUCCESS,
            }),
          )

          fetchProfile()
        })
    } catch (e) {
      if (isAxiosError(e) && e.response?.data) {
        dispatch(
          showNotification({
            message: e.response.data.message,
            title: NotificationTitleType.ERROR,
          }),
        )
        return
      }

      dispatch(
        showNotification({
          message: 'Error! Please ensure you have internet connection and have filled every field.',
          title: NotificationTitleType.ERROR,
        }),
      )
    } finally {
      setLoading(false)
    }
  }

  const updateHandle = async () => {
    if (handle.length === 0) {
      dispatch(
        showNotification({
          message: 'Please enter your unique handle.',
          title: NotificationTitleType.ERROR,
        }),
      )
      return
    }

    try {
      setLoading(true)

      await axios
        .patch(
          `http://localhost:8000/api/users/update-handle/${handle}`,
          {},
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${loginState?.tokens.accessToken}`,
            },
          },
        )
        .then(() => {
          dispatch(
            showNotification({
              message: 'Awesome job! You have now your own custom handle.',
              title: NotificationTitleType.SUCCESS,
            }),
          )

          fetchProfile()
        })
    } catch (e) {
      if (isAxiosError(e) && e.response?.data) {
        dispatch(
          showNotification({
            message: e.response.data.message,
            title: NotificationTitleType.ERROR,
          }),
        )
        return
      }

      dispatch(
        showNotification({
          message: 'Error! Please ensure you have internet connection and have filled every field.',
          title: NotificationTitleType.ERROR,
        }),
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <section className={`${styles.index} ${globals['full-width']}`}>
      <div className={`${styles.headline}`}>
        <h2>Profile Management</h2>
        <p>Help us personalize your experience and support AfrikMuzik in your best wau possible.</p>
      </div>

      <div className={`${globals.flex} ${styles.form__holder} ${globals['gap-16']}`}>
        <div className={`${globals.flex} ${globals['gap-12']} ${globals['flex-column']}`}>
          <div className={`${styles.image}`}>
            <img
              src={
                image
                  ? URL.createObjectURL(image)
                  : loginState?.tokens.user.profileImage
                  ? loginState.tokens.user.profileImage.httpPath
                  : ImageAssets.MaleAvatar
              }
              width='100%'
              height='100%'
              alt='user'
            />
            <div className={styles.edit}>
              <AiOutlineEdit fontSize='3rem' />
              <input
                onChange={(e) => {
                  if (e.target.files) {
                    setImage(e.target.files[0])
                  }
                }}
                type='file'
                name='image'
                id='image'
              />
            </div>
          </div>
          <div>
            <ButtonAtom
              buttonType={ButtonTypes.PRIMARY}
              label='Save'
              fontSize='1.6rem'
              rounded={false}
              onClick={(e) => {
                e.preventDefault()
                updateProfileImage()
              }}
              padding='10px 24px'
              height='56px'
              loading={loading}
            />
          </div>
        </div>
        <div
          className={`${styles.form} ${globals.flex} ${globals['flex-column']} ${globals['gap-16']}`}
        >
          <div className={styles.form__control}>
            <div className={styles.label}>
              <label htmlFor='firstName'>First Name</label>
            </div>
            <div className={styles.input__arena}>
              <div className={styles.icon__left}>
                <AiOutlineUser size={17} />
              </div>
              <input
                type='text'
                onChange={(e) => {
                  setFirstName(e.target.value)
                }}
                value={firstName}
                placeholder='First Name'
                name='firstname'
              />
            </div>
          </div>

          <div className={styles.form__control}>
            <div className={styles.label}>
              <label htmlFor='lastName'>Last Name</label>
            </div>
            <div className={styles.input__arena}>
              <div className={styles.icon__left}>
                <AiOutlineUser size={17} />
              </div>
              <input
                type='text'
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                placeholder='Last Name'
                name='lastName'
              />
            </div>
          </div>

          <div className={styles.form__control}>
            <div className={styles.label}>
              <label htmlFor='email'>Email</label>
            </div>
            <div className={styles.input__arena}>
              <div className={styles.icon__left}>
                <AiOutlineMail size={17} />
              </div>
              <input
                type='text'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder='Email'
                name='email'
              />
            </div>
          </div>

          <div className={styles.form__control}>
            <div className={styles.label}>
              <label htmlFor='contact'>Contact or Phone Number</label>
            </div>
            <div className={styles.input__arena}>
              <div className={styles.icon__left}>
                <AiOutlinePhone size={17} />
              </div>
              <input
                type='text'
                onChange={(e) => setContact(e.target.value)}
                value={contact}
                placeholder='Contact'
                name='contact'
              />
            </div>
          </div>

          <div className={styles.form__control}>
            <div className={styles.label}>
              <label htmlFor='location'>Your Address</label>
            </div>
            <div className={styles.input__arena}>
              <GooglePlaceInput
                placeholder='Start typing to select location'
                onSelect={(place: Partial<Place> | undefined) => {
                  setLocation(place)
                }}
                defaultValue={location?.formattedAddress}
              />
            </div>
          </div>

          <div style={{ marginTop: '1rem' }}>
            <ButtonAtom
              buttonType={ButtonTypes.PRIMARY}
              label='Update Profile'
              fontSize='1.6rem'
              rounded={false}
              onClick={() => {
                console.log('====================================')
                console.log('Submitted')
                console.log('====================================')
              }}
              padding='10px 24px'
              height='56px'
              loading={loading}
            />
          </div>

          <div className={styles.form__control}>
            <div className={styles.label}>
              <label htmlFor='handle'>Unique Handle</label>
            </div>
            <div className={styles.input__arena}>
              <div className={styles.icon__left}>
                <AiOutlineUser size={17} />
              </div>
              <input
                type='text'
                onChange={(e) => setHandle(e.target.value)}
                value={handle}
                placeholder='afrikmuzik'
                name='handle'
              />
            </div>
          </div>

          <div>
            <ButtonAtom
              buttonType={ButtonTypes.PRIMARY}
              label='Update Handle'
              fontSize='1.6rem'
              rounded={false}
              onClick={(e) => {
                e.preventDefault()
                updateHandle()
              }}
              padding='10px 24px'
              height='56px'
              loading={loading}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProfileView
