import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ModalType } from '../../../types/enum.type'

type InitialStateType = {
  modalName: ModalType | null
}

export const initialState: InitialStateType = {
  modalName: null,
}

const modalSlice = createSlice({
  name: 'modalSlice',
  initialState: initialState,
  reducers: {
    closeModal: (state) => {
      state.modalName = null
    },
    openModal: (state, action: PayloadAction<{ modalName: ModalType }>) => {
      state.modalName = action.payload.modalName
    },
  },
  extraReducers: (builder) => {
    builder
  },
})

export const { closeModal, openModal } = modalSlice.actions
export default modalSlice.reducer
