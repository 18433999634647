import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import globals from '../../../globals.module.css'
import styles from './audiocard.module.css'

import ControlPanel from './modules/controls/ControlPanel'
import Slider from './modules/slider/Slider'

type Props = {
  index: number
  image: string
  artistName: string
  songName: string
}

const AudioCardComponent: React.FC<Props> = ({ index, image, artistName, songName }) => {
  const [percentage, setPercentage] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  // const [audioVolume, setAudioVolume] = useState(0.1)

  const audioRef = useRef<HTMLAudioElement>(null)

  const onChange = (e: any) => {
    const audio = audioRef.current
    if (audio) {
      audio.currentTime = (audio.duration / 100) * e.target.value
    }
    setPercentage(e.target.value)
  }

  const play = () => {
    const audio = audioRef.current
    // if (audio) {
    //   // audio.volume = audioVolume
    // }

    if (!isPlaying) {
      setIsPlaying(true)
      audio?.play()
    }

    if (isPlaying) {
      setIsPlaying(false)
      audio?.pause()
    }
  }

  // useEffect(() => {
  //   const audio = audioRef.current
  //   if (audio) {
  //     audio.volume = audioVolume
  //   }
  // }, [audioVolume])

  const getCurrentDuration = (e: any) => {
    const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2)
    const time = e.currentTarget.currentTime

    setPercentage(+percent)
    setCurrentTime(time.toFixed(2))
  }

  const [isLiked, setIsLiked] = useState(false)

  const handleLikeState = () => {
    setIsLiked(!isLiked)
    console.log('Like button clicked!!!')
  }

  return (
    <div className={styles.audio__card}>
      <div className={`${globals.flex} ${globals['center-items']} ${styles.icon__wrapper}`}>
        <svg
          width='24'
          height='25'
          viewBox='0 0 24 25'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M20.3121 4.00679C18.023 1.77821 14.9936 0.5 12 0.5C9.00643 0.5 5.97696 1.77821 3.68786 4.00679C1.30982 6.32161 0 9.33929 0 12.5C0 13.9288 0.46875 15.7727 1.76143 19.2259C3.05411 22.6791 5.625 24.125 6.71089 24.3613C7.02214 24.4293 7.38696 24.5 7.71429 24.5C8.2783 24.5005 8.83254 24.3527 9.32143 24.0714L10.0714 23.6429C10.8788 23.1704 11.1145 22.1359 10.65 21.3205L5.99357 13.1471C5.88262 12.9516 5.73396 12.7799 5.55621 12.6422C5.37846 12.5045 5.17515 12.4034 4.95805 12.3448C4.74096 12.2862 4.5144 12.2713 4.29149 12.3009C4.06858 12.3304 3.85376 12.404 3.65946 12.5171L2.92607 12.9457C2.6442 13.1108 2.39092 13.3205 2.17607 13.5666C2.1494 13.5979 2.1143 13.6209 2.07494 13.6327C2.03559 13.6446 1.99364 13.645 1.95411 13.6337C1.91458 13.6224 1.87913 13.5999 1.85199 13.569C1.82486 13.5382 1.80718 13.5001 1.80107 13.4595C1.74707 13.1424 1.71806 12.8216 1.71429 12.5C1.71429 9.80482 2.83929 7.22429 4.88357 5.23518C6.85714 3.31518 9.44946 2.21429 12 2.21429C14.5505 2.21429 17.1429 3.31518 19.1164 5.23518C21.1607 7.22429 22.2857 9.80482 22.2857 12.5C22.2809 12.8217 22.2508 13.1425 22.1957 13.4595C22.1896 13.5001 22.1719 13.5382 22.1448 13.569C22.1177 13.5999 22.0822 13.6224 22.0427 13.6337C22.0031 13.645 21.9612 13.6446 21.9218 13.6327C21.8825 13.6209 21.8474 13.5979 21.8207 13.5666C21.6059 13.3205 21.3526 13.1108 21.0707 12.9457L20.3373 12.5171C20.143 12.404 19.9282 12.3304 19.7053 12.3009C19.4824 12.2713 19.2558 12.2862 19.0387 12.3448C18.8216 12.4034 18.6183 12.5045 18.4406 12.6422C18.2628 12.7799 18.1142 12.9516 18.0032 13.1471L13.35 21.3205C12.8855 22.1359 13.1213 23.1704 13.9286 23.6429L14.6786 24.0714C15.1675 24.3527 15.7217 24.5005 16.2857 24.5C16.613 24.5 16.9779 24.4293 17.2891 24.3613C18.375 24.125 20.9464 22.6786 22.2386 19.2259C23.5307 15.7732 24 13.9288 24 12.5C24 9.33929 22.6902 6.32161 20.3121 4.00679Z'
            fill='white'
          />
        </svg>
      </div>
      <Link to={`/audio/${index}`}>
        <img src={image} alt='song cover' />
      </Link>
      <div className={styles.audio__details}>
        <div className={styles.title__range}>
          <div>
            <h3>{songName}</h3>
            {/* <h3>
              {audioVolume * 100 === 0 ? (
                // <img src={AppIcons.mic_icon_off} alt='mic' />
                <IconRepository.AddressIcon />
              ) : (
                // <img src={AppIcons.mic_icon} alt='mic' />
                <IconRepository.AddressIcon />
              )}
              {(audioVolume * 100).toFixed(0)}%
            </h3> */}
          </div>
          {/* <div>
            <h4>Volume</h4>
            <input
              value={audioVolume * 100}
              onChange={(e) => setAudioVolume(Number(e.target.value) / 100)}
              type='range'
              name='volume'
              id='volume'
            />
          </div> */}
        </div>
        <Slider percentage={percentage} onChange={onChange} />
        <audio
          ref={audioRef}
          onTimeUpdate={getCurrentDuration}
          onLoadedData={(e) => {
            setDuration(Number(e.currentTarget.duration.toFixed(2)))
          }}
          src={'/audio/music.mp3'}
        ></audio>
        <ControlPanel
          play={play}
          isPlaying={isPlaying}
          duration={duration}
          currentTime={currentTime}
        />
        <div className={styles.artist__like}>
          <div className={styles.artist}>
            <img src='/images/team/emma.webp' alt='artist' />
            <h4>{artistName}</h4>
          </div>
          <div onClick={() => handleLikeState()} className={styles.like__panel}></div>
        </div>
      </div>
    </div>
  )
}

export default AudioCardComponent
