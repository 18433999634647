import { IconType } from '../types/icons.type'

const GoogleIcon = ({ size = '24', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.74 11.07C21.7154 10.825 21.6002 10.5981 21.4172 10.4335C21.2341 10.2689 20.9962 10.1785 20.75 10.18H13.2C12.65 10.18 12.2 10.63 12.2 11.18V12.89C12.2 13.44 12.65 13.89 13.2 13.89H17.71C17.6 14.81 17 16.2 15.67 17.13C14.82 17.72 13.69 18.13 12.2 18.13C12.13 18.13 12.07 18.13 12 18.12C9.45 18.04 7.29 16.33 6.51 13.98C6.2937 13.3422 6.18225 12.6735 6.18 12C6.18 11.31 6.3 10.64 6.5 10.02C6.56 9.84 6.63 9.66 6.71 9.48C7.63 7.41 9.64 5.95 12 5.88C12.06 5.87 12.13 5.87 12.2 5.87C13.63 5.87 14.7 6.34 15.45 6.86C15.84 7.13 16.36 7.07 16.7 6.74L18.09 5.38C18.53 4.95 18.49 4.22 17.99 3.86C16.4 2.69 14.46 2 12.2 2C12.13 2 12.07 2 12 2.01C10.1813 2.04221 8.40625 2.57184 6.86727 3.54141C5.3283 4.51098 4.08421 5.88348 3.27 7.51C2.59 8.87 2.2 10.39 2.2 12C2.2 13.61 2.59 15.13 3.27 16.49H3.28C4.09307 18.1153 5.33551 19.4871 6.87265 20.4566C8.40978 21.4261 10.183 21.9564 12 21.99C12.07 22 12.13 22 12.2 22C14.9 22 17.17 21.11 18.82 19.58C20.71 17.83 21.8 15.27 21.8 12.22C21.8 11.79 21.78 11.42 21.74 11.07Z'
        fill={fillColor}
      />
    </svg>
  )
}

export default GoogleIcon
