import { MantineProvider, createTheme } from '@mantine/core'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

const theme = createTheme({
  /** Put your mantine theme override here */
})

import reportWebVitals from './reportWebVitals'

// global styles
import '@mantine/core/styles.css'
import '@mantine/tiptap/styles.css'
import './index.css'

// application entry point
import App from './App'

import { store } from './app/store/store'
import { ThemeProvider } from './layouts/theme/Theme.layout'
import './lib/i18next/i18next'
import * as serviceWorkerRegistration from './service-worker-registration'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider theme={theme}>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </MantineProvider>
    </Provider>
  </React.StrictMode>,
)

serviceWorkerRegistration.register()

reportWebVitals()
