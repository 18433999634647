import axios from 'axios'

import { backend, publicApiRequest } from '../../lib/hooks/axios-instance'
import { User, UserResponse } from '../../types'
import { ApiResponse, RegisterUserRequest, VerifyEmailType } from '../../types/api.types'
import { LoginDataType } from '../../types/auth/login.types'
import { apiRoutes } from './routes'

export class AuthenticationService {
  public async loginUser(data: LoginDataType) {
    return await publicApiRequest().post<{
      data: {
        user: User
        token: string
      }
      success: string
    }>(apiRoutes.auth.login, data)
  }
}

export async function verifyUserEmail(payload: VerifyEmailType) {
  return backend.post<{ data: { message: string } }>(apiRoutes.auth.verifyEmail, payload)
}

export async function logoutUser() {
  return axios.post<ApiResponse<UserResponse>>(`${process.env.REACT_APP_BARE_SERVER_URL}/logout`)
}

export async function registerWithGoogle(credential: string) {
  return backend.post<ApiResponse<UserResponse>>(apiRoutes.auth.googleRegister, { credential })
}

export async function loginWithGoogle(credential: string) {
  return backend.post<ApiResponse<UserResponse>>(apiRoutes.auth.googleLogin, { credential })
}

export async function registerUser(payload: RegisterUserRequest) {
  return backend.post<ApiResponse<UserResponse>>(apiRoutes.auth.register, payload)
}
