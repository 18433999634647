import { useCallback, useRef } from 'react'

import globals from '../../../globals.module.css'
import styles from './popup.module.css'

import UseOnClickOutside from '../../../lib/hooks/detect-clickoutside.hook'

type Props = {
  children: React.ReactNode
  togglePopup: React.Dispatch<React.SetStateAction<boolean>>
}

const PopupModalMolecule = ({ togglePopup, children }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const outsideClickHandler = useCallback(() => {
    togglePopup(() => {
      return false
    })
  }, [])

  UseOnClickOutside(ref, outsideClickHandler)

  return (
    <div
      className={`${styles.popup} ${globals['full-width']} ${globals.flex} ${globals['flex-column']} ${globals['center-items']}`}
    >
      <div ref={ref}>{children}</div>
    </div>
  )
}

export default PopupModalMolecule
