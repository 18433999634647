import { createSlice } from '@reduxjs/toolkit'

import { getUserInfo } from '../../../../services/storage/localstorage.service'
import { loginUserThunk } from '../thunks/auth.thunk'
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from './../../../../types/api.types'

export type LoginUserState = {
  requestResponse: ApiRequestDataType
}

const userInfoFromStorage = getUserInfo()

export const initialState: LoginUserState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data:
      userInfoFromStorage !== undefined && userInfoFromStorage !== null
        ? userInfoFromStorage
        : null,
  },
}

const loginUserSlice = createSlice({
  name: 'loginUserSlice',
  initialState: initialState,
  reducers: {
    resetLoginUserState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(loginUserThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload
      })
      .addCase(loginUserThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetLoginUserState } = loginUserSlice.actions
export default loginUserSlice.reducer
