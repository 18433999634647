import { IconType } from '../types/icons.type'

const CloudSuccessIcon = ({ size = '24', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.54 11.12C0.859999 11.45 0.859999 18.26 5.54 18.59H7.46M5.59 11.12C2.38 2.19 15.92 -1.38 17.47 8C21.8 8.55 23.55 14.32 20.27 17.19C19.27 18.1 17.98 18.6 16.63 18.59H16.54'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17 16.53C17 17.27 16.84 17.97 16.54 18.59C16.46 18.77 16.37 18.94 16.27 19.1C15.8305 19.8409 15.2056 20.4546 14.4569 20.8807C13.7082 21.3068 12.8615 21.5306 12 21.53C10.18 21.53 8.59 20.55 7.73 19.1C7.63 18.94 7.54 18.77 7.46 18.59C7.16 17.97 7 17.27 7 16.53C7 13.77 9.24 11.53 12 11.53C14.76 11.53 17 13.77 17 16.53Z'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.44 16.53L11.43 17.52L13.56 15.55'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CloudSuccessIcon
