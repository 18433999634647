import { useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'

import globals from '../../globals.module.css'
import styles from './dashboard.module.css'

import { ImageAssets } from '../../app/assets'
import { appConstants } from '../../app/constants'
import { resetNewAuthState } from '../../app/features/auth/slices/new-auth-slice'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import { IconRepository } from '../../repository/icons.repository'
import { accountTypesObj } from '../auth/register/Register.page'

const DashboardPage = () => {
  const location = useLocation()
  const [profileDrop, setProfileDrop] = useState(false)
  const [sideBar, setSideBar] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const newAuthState = useAppSelector((state) => state.newAuthSlice)
  const { loginState } = newAuthState

  useEffect(() => {
    if (!newAuthState?.loginState?.tokens?.user) {
      navigate(appConstants.routes.auth.loginRefLink)
    }
  }, [newAuthState])

  return (
    <div className={`${styles.dashboard} ${globals['full-width']}`}>
      <aside className={`${styles.sidebar} ${sideBar ? styles.active : ''}`}>
        <div
          className={`${styles.logo} ${globals.flex} ${globals['a-center']} ${globals['gap-24']} ${globals['s-b']}`}
        >
          <Link to={'/'}>
            <IconRepository.LogoIcon width='150' />
          </Link>
          <div
            onClick={() => {
              setSideBar(!sideBar)
            }}
            className={styles.bars}
          >
            <IconRepository.BadgeBagIcon fillColor='#fff' />
          </div>
        </div>

        <ul className={`${styles.sidebar__links} ${globals.flex} ${globals['flex-column']}`}>
          <li>
            <Link
              className={location.pathname === '/app/dashboard' ? styles.active : ''}
              to={'/app/dashboard'}
            >
              <IconRepository.AddressIcon
                size='18'
                fillColor={
                  location.pathname === '/app/dashboard'
                    ? 'var(--WHITE_COLOR)'
                    : 'var(--DARK_COLOR)'
                }
              />{' '}
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname.includes('/subscriptions') ? styles.active : ''}
              to={'/app/dashboard/subscriptions'}
            >
              <IconRepository.CVBuilderIcon
                size='18'
                fillColor={
                  location.pathname.includes('/subscriptions')
                    ? 'var(--WHITE_COLOR)'
                    : 'var(--DARK_COLOR)'
                }
              />
              Subscriptions
            </Link>
          </li>
          {accountTypesObj.musician === loginState?.tokens.user.accountType ? (
            <li>
              <Link
                className={location.pathname.includes('/events') ? styles.active : ''}
                to={'/app/dashboard/events'}
              >
                <IconRepository.CheckedIcon
                  size='18'
                  fillColor={
                    location.pathname.includes('/events')
                      ? 'var(--WHITE_COLOR)'
                      : 'var(--DARK_COLOR)'
                  }
                />
                Events & Tickets
              </Link>
            </li>
          ) : (
            ''
          )}
          <li>
            <Link to={'/app/dashboard'}>
              <IconRepository.CloudFailureIcon size='18' fillColor='var(--DARK_COLOR)' />
              Trending
            </Link>
          </li>
          <li>
            <Link to={'/app/dashboard'}>
              <IconRepository.SuggestionBoxIcon size='18' fillColor='var(--DARK_COLOR)' />
              Playlists
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname === '/app/dashboard/profile' ? styles.active : ''}
              to={'/app/dashboard/profile'}
            >
              <IconRepository.UserIcon
                size='18'
                fillColor={
                  location.pathname === '/app/dashboard/profile'
                    ? 'var(--WHITE_COLOR)'
                    : 'var(--DARK_COLOR)'
                }
              />{' '}
              My Profile
            </Link>
          </li>
        </ul>
      </aside>
      <div className={`${styles.content}`}>
        <div className={`${globals.flex} ${globals['s-b']} ${globals['gap-24']}`}>
          <div className={`${globals.flex} ${globals['center-items']} ${globals['gap-8']}`}>
            <div
              onClick={() => {
                setSideBar(!sideBar)
              }}
              className={styles.bars}
            >
              <IconRepository.BadgeBagIcon fillColor='#fff' />
            </div>
            <h2>
              Welcome, <span>{loginState?.tokens?.user?.firstName}</span>
            </h2>
          </div>

          <div className={`${styles.profile}`}>
            <div
              onClick={() => {
                setProfileDrop(!profileDrop)
              }}
              className={`${styles.image} ${profileDrop ? styles.active : ''} ${globals.flex} ${
                globals['center-items']
              }`}
            >
              <img
                src={
                  loginState?.tokens?.user?.profileImage
                    ? loginState?.tokens?.user?.profileImage.httpPath
                    : ImageAssets.MaleAvatar
                }
                width='100%'
                height='100%'
                alt='profile'
              />
            </div>

            <div
              className={`${styles.drop} ${profileDrop ? styles.show : ''} ${globals.flex} ${
                globals['flex-column']
              }`}
            >
              {loginState?.tokens?.user?.handle ? (
                <h4>{`@${loginState?.tokens?.user?.handle}`}</h4>
              ) : (
                ''
              )}
              <ul className={`${styles.menu__list} ${globals.flex} ${globals['flex-column']}`}>
                <li>
                  <Link to={'/app/dashboard/profile'}>
                    <IconRepository.UserIcon size='18' fillColor='var(--WHITE_COLOR)' />
                    Profile Management
                  </Link>
                </li>
                <li>
                  <Link to={'/app/dashboard/settings'}>
                    <IconRepository.SettingsIcon size='18' fillColor='var(--WHITE_COLOR)' />
                    Settings
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      dispatch(resetNewAuthState())
                    }}
                    to={'/#'}
                  >
                    <IconRepository.LockIcon size='18' fillColor='var(--WHITE_COLOR)' />
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={`${styles.views}`}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default DashboardPage
