import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import globals from '../../../globals.module.css'
import styles from './footer.module.css'

import { appConstants } from '../../../app/constants'
import { IconRepository } from '../../../repository/icons.repository'

const FooterOrganism = () => {
  const { t } = useTranslation()

  return (
    <footer
      className={`${styles.footer} ${globals['p-desktop']} ${globals.flex} ${globals['flex-column']} ${globals['gap-32']}`}
    >
      <div className={styles.logo}>
        <Link to={appConstants.routes.landingPageRef}>
          <IconRepository.LogoIcon width='180' />
        </Link>
        <p>
          {t(
            'Redefined music, elevated craft, expanded audience. Join us to transform your sound into global resonance. Your journey starts here.',
            { ns: ['main', 'home'] },
          )}
        </p>
      </div>

      <div className={`${styles.footer_component} ${globals.flex}`}>
        <div className={styles.sub}>
          <h3>Site Links</h3>

          <ul className={styles.footer__list}>
            <li>
              <Link to={'/welcome'}>Sign Up</Link>
            </li>
            <li>
              <Link to={'/auth/login'}>Login</Link>
            </li>
          </ul>
        </div>
        <div className={styles.sub}>
          <h3>Teams</h3>

          <ul className={styles.footer__list}>
            <li>
              <Link to={appConstants.routes.teamRef}>The Core Team</Link>
            </li>
          </ul>
        </div>
        {/* <div className={styles.sub}>
          <h3>Industries</h3>

          <ul className={styles.footer__list}>
            <li>
              <Link to={appConstants.routes.landingPage}>Guiness</Link>
            </li>
            <li>
              <Link to={appConstants.routes.landingPage}>General Supreme</Link>
            </li>
            <li>
              <Link to={appConstants.routes.landingPage}>Andela</Link>
            </li>
            <li>
              <Link to={appConstants.routes.landingPage}>Pluralsight</Link>
            </li>
          </ul>
        </div> */}
        <div className={styles.sub}>
          <h3>Companies</h3>

          <ul className={styles.footer__list}>
            <li>
              <Link to={appConstants.routes.landingPageRef}>Music Loop</Link>
            </li>
          </ul>
        </div>
        {/* <div className={styles.sub}>
          <h3>Resources</h3>

          <ul className={styles.footer__list}>
            <li>
              <Link to={appConstants.routes.landingPage}>Building a Tech Startup</Link>
            </li>
            <li>
              <Link to={appConstants.routes.landingPage}>Grants Accumulation</Link>
            </li>
            <li>
              <Link to={appConstants.routes.landingPage}>Sequential Access Control</Link>
            </li>
            <li>
              <Link to={appConstants.routes.landingPage}>Programming</Link>
            </li>
          </ul>
        </div> */}
        {/* <div className={styles.sub}>
          <h3>Connect</h3>

          <ul className={styles.footer__list}>
            <li>
              <Link to={appConstants.routes.landingPage}>Github</Link>
            </li>
            <li>
              <Link to={appConstants.routes.landingPage}>Linkedin</Link>
            </li>
            <li>
              <Link to={appConstants.routes.landingPage}>Twitter</Link>
            </li>
            <li>
              <Link to={appConstants.routes.landingPage}>WhatsApp</Link>
            </li>
          </ul>
        </div> */}
      </div>

      <div className={`${styles.mark} ${globals.flex} ${globals['gap-32']} ${globals['s-b']}`}>
        <h4>
          Music Loop, {t('Roots from Cameroon', { ns: ['main', 'home'] })} | &copy;{' '}
          {new Date().getFullYear()}
        </h4>
        <div className={`${styles.links} ${globals.flex} ${globals['gap-24']}`}>
          <Link to={appConstants.routes.landingPageRef}>
            {t('Privacy', { ns: ['main', 'home'] })}
          </Link>
          <Link to={appConstants.routes.landingPageRef}>
            {t('Terms of Use', { ns: ['main', 'home'] })}
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default FooterOrganism
