import React from 'react'

import globals from '../../globals.module.css'
import styles from './generallayout.module.css'

import FooterOrganism from '../../components/organisms/footer/Footer.organism'
import NavbarOrganism from '../../components/organisms/navbar/Navbar.organism'

type PropsType = {
  children: React.ReactNode
}

const GeneralLayout: React.FC<PropsType> = ({ children }) => {
  return (
    <div
      className={`${styles.general__layout} ${globals['v-height']} ${globals['full-width']} ${globals.flex} ${globals['flex-column']} ${globals['j-center']}`}
    >
      <NavbarOrganism />
      <div className={styles.child}>{children}</div>
      <FooterOrganism />
    </div>
  )
}

export default GeneralLayout
