import React from 'react'

import styles from './simplecarousel.module.css'

const SimpleCarousel: React.FC<Props> = ({ children, items, activeIndex }) => {
  return (
    <div className={styles.carousel__wrapper}>
      <div className={styles.carousel__items__wrapper}>
        {Array(items)
          .fill(items)
          .map((_, index) => (
            <div
              key={index}
              className={`${activeIndex > index ? styles.carousel__next : ''} ${
                activeIndex === index ? styles.carousel__current : ''
              } ${activeIndex < index ? styles.carousel__prev : ''}`}
            >
              {children}
            </div>
          ))}
      </div>
    </div>
  )
}

export default SimpleCarousel

type Props = {
  children: JSX.Element
  items: number
  activeIndex: number
}
