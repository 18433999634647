import { IconType } from '../types/icons.type'

const BadgeBagIcon = ({ size = '24', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 18.2C13.0609 18.2 14.0783 17.7786 14.8284 17.0284C15.5786 16.2783 16 15.2609 16 14.2C16 13.1391 15.5786 12.1217 14.8284 11.3716C14.0783 10.6214 13.0609 10.2 12 10.2C10.9391 10.2 9.92172 10.6214 9.17157 11.3716C8.42143 12.1217 8 13.1391 8 14.2C8 15.2609 8.42143 16.2783 9.17157 17.0284C9.92172 17.7786 10.9391 18.2 12 18.2Z'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.44 14.3L11.09 14.95C11.28 15.14 11.59 15.14 11.78 14.96L13.56 13.32'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 6V5.2C8 3.43 8 2 11.2 2H12.8C16 2 16 3.43 16 5.2V6M8 22H16C20.02 22 20.74 20.39 20.95 18.43L21.7 10.43C21.97 7.99 21.27 6 17 6H7C2.73 6 2.03 7.99 2.3 10.43L3.05 18.43C3.26 20.39 3.98 22 8 22Z'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.65 11C19.9567 12.2335 18.0419 13.1298 16.01 13.64M2.62 11.27C4.29 12.41 6.11 13.22 8 13.68'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default BadgeBagIcon
