import globals from '../../globals.module.css'
import styles from './audiodetails.module.css'

import AudioCardComponent from '../../components/molecules/audio-card/AudioCard.molecule'
import BannerTemplate from '../../components/templates/audio-details/banner/Banner.template'
import GeneralLayout from '../../layouts/general/General.layout'
import Internalization from '../../layouts/internationalization/Internalization'
import { popularSongs } from '../../repository/data.repository'

const AudioDetailsPage = () => {
  return (
    <Internalization>
      <GeneralLayout>
        <div
          className={`${globals['full-width']} ${globals['p-desktop']} ${styles.audio__details} ${globals.flex} ${globals['gap-12']}`}
        >
          <div className={`${globals['full-width']} ${styles.banner__wrapper}`}>
            <BannerTemplate />
          </div>
          <div>
            <h2>Recent Uploads</h2>
            <div
              className={`${globals.flex} ${globals['flex-column']} ${styles.cards} ${globals['gap-12']}`}
            >
              {popularSongs.slice(0, 3).map((data, index) => {
                return (
                  <div key={index}>
                    <AudioCardComponent
                      image={data.image}
                      artistName={data.artistName}
                      songName={data.songName}
                      index={index}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </GeneralLayout>
    </Internalization>
  )
}

export default AudioDetailsPage
