import { IconType } from '../types/icons.type'

const DarkModeIcon = ({ size = '24', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.03 12.42C2.39 17.57 6.76 21.76 11.99 21.99C15.68 22.15 18.98 20.43 20.96 17.72C21.78 16.61 21.34 15.87 19.97 16.12C19.3 16.24 18.61 16.29 17.89 16.26C13 16.06 9 11.97 8.98 7.14001C8.97 5.84001 9.24 4.61001 9.73 3.49001C10.27 2.25001 9.62 1.66001 8.37 2.19001C4.41 3.86001 1.7 7.85001 2.03 12.42Z'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default DarkModeIcon
