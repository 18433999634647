import { createAsyncThunk } from '@reduxjs/toolkit'

// general imports
import { getExceptionPayload } from '../../../../lib/utils/get-exception'
import { PhoneNumberService } from '../../../../services/api/phone-number.service'
import { ApiRequestErrorType } from '../../../../types/api.types'

const phoneNumberService = new PhoneNumberService()

export type PhoneNumberType = {
  name: string
  callingCodes: string[]
  flags: {
    png: string
  }
  alpha3Code: string
}

export type ExtractedPhoneNumberType = {
  name: string
  callingCode: string
  flag: string
  alpha3Code: string
}

export const getPhoneNumbersThunk = createAsyncThunk<
  PhoneNumberType[],
  void,
  { rejectValue: ApiRequestErrorType }
>('/get/phoneNumbers', async (_, { rejectWithValue }) => {
  try {
    const response = await phoneNumberService.getAllPhoneNumbers()
    return response?.data
  } catch (ex) {
    return rejectWithValue(getExceptionPayload(ex) as ApiRequestErrorType)
  }
})
