import { IconType } from '../types/icons.type'

const SuggestionBoxIcon = ({ size = '24', fillColor = 'currentColor' }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.49 17.98H17.51M19 16.52V19.51M23 18C23 18.75 22.79 19.46 22.42 20.06C22.21 20.42 21.94 20.74 21.63 21C20.93 21.63 20.01 22 19 22C17.78 22 16.69 21.45 15.97 20.59C15.95 20.56 15.92 20.54 15.9 20.51C15.78 20.37 15.67 20.22 15.58 20.06C15.1993 19.4404 14.9985 18.7272 15 18C15 16.74 15.58 15.61 16.5 14.88C17.19 14.33 18.06 14 19 14C20 14 20.9 14.36 21.6 14.97C21.72 15.06 21.83 15.17 21.93 15.28C22.59 16 23 16.95 23 18Z'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.17 7.44L12 12.55L20.77 7.47M12 21.61V12.54'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.61 9.17V14.83C21.61 14.88 21.61 14.92 21.6 14.97C20.9 14.36 20 14 19 14C18.06 14 17.19 14.33 16.5 14.88C16.0306 15.253 15.6518 15.7275 15.392 16.2679C15.1322 16.8083 14.9982 17.4004 15 18C15 18.75 15.21 19.46 15.58 20.06C15.67 20.22 15.78 20.37 15.9 20.51L14.07 21.52C12.93 22.16 11.07 22.16 9.93 21.52L4.59 18.56C3.38 17.89 2.39 16.21 2.39 14.83V9.17C2.39 7.79 3.38 6.11 4.59 5.44L9.93 2.48C11.07 1.84 12.93 1.84 14.07 2.48L19.41 5.44C20.62 6.11 21.61 7.79 21.61 9.17Z'
        stroke={fillColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SuggestionBoxIcon
