/* eslint-disable */

import i18next from 'i18next'
import detector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import translationEnglish from '../resources/english/en.json'
import translationEnglish2 from '../resources/english/en2.json'

import translationFrench from '../resources/french/fr.json'

import translationGerman from '../resources/german/de.json'

import { LocalStorageService } from '../../services/storage/localstorage.service'

const LocalStorage = new LocalStorageService()

const userLang =
  LocalStorage.getAppLang().appLang || navigator.language || (navigator as any).userLanguage

const lng = userLang?.includes('-') ? userLang.split('-')[0] : userLang

if (lng) {
  LocalStorage.storeAppLang(lng)
}

const resources = {
  en: {
    main: translationEnglish,
    home: translationEnglish2,
  },
  fr: {
    main: translationFrench,
  },
  de: {
    main: translationGerman,
  },
}

i18next
  .use(detector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['path', 'subdomain'],
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
    },
    resources,
    lng,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18next
