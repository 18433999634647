import globals from '../../../globals.module.css'
import styles from './button.module.css'

import { ButtonLooks, ButtonTypes, IconDirection } from '../../../types/enum.type'
import ComponentLoadingAtom from '../component-loading-state/ComponentLoading.atom'

type ButtonPropsType = {
  label?: string
  className?: string
  iconDir?: IconDirection
  icon?: JSX.Element
  disabled?: boolean
  fontSize?: string
  fontWeight?: string
  borderRadius?: string
  padding?: string
  buttonType?: ButtonTypes
  buttonLook?: ButtonLooks
  height?: string
  width?: string
  rounded?: boolean
  borderWidth?: number
  elevated?: boolean
  loading?: boolean

  // eslint-disable-next-line
  onClick?: (e?: any) => void
}

const ButtonAtom: React.FC<ButtonPropsType> = ({
  label,
  loading,
  onClick,
  width,
  elevated,
  fontSize,
  rounded,
  borderWidth,
  fontWeight,
  disabled,
  className,
  height,
  iconDir,
  borderRadius,
  padding,
  buttonLook,
  buttonType,
  icon,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        fontSize,
        fontWeight,
        borderRadius: rounded ? '50px' : borderRadius,
        padding,
        minHeight: height,
        width,
        borderWidth,
        boxShadow: elevated ? 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px' : 'none',
      }}
      className={`${globals.flex} ${
        buttonType === ButtonTypes.PRIMARY
          ? styles.primary
          : buttonType === ButtonTypes.SECONDARY
          ? styles.secondary
          : styles.tertiary
      } ${buttonLook === ButtonLooks.OUTLINED ? styles.outlined : styles.solid} ${styles.button} ${
        globals['gap-8']
      } ${globals['center-items']} ${globals.flex} ${globals.delay} ${className}`}
    >
      {iconDir === IconDirection.LEFT ? <>{icon}</> : null}
      {loading ? (
        <ComponentLoadingAtom
          loaderColor={buttonLook === ButtonLooks.OUTLINED ? 'var(--ROYALTY)' : 'white'}
          size='100%'
        />
      ) : (
        <span>{label}</span>
      )}
      {iconDir === IconDirection.RIGHT ? <>{icon}</> : null}
    </button>
  )
}

export default ButtonAtom
