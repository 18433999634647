import { AiOutlineCheckCircle } from 'react-icons/ai'

import styles from './pricing.module.css'

import { IconRepository } from '../../../repository/icons.repository'
import { ButtonLooks, ButtonTypes } from '../../../types/enum.type'
import ButtonAtom from '../../atoms/button/Button.atom'

type Props = {
  planType: string
  price: string
  features: string[]
  headBgColor?: string
}

const PricingCard = ({ planType, headBgColor, price, features }: Props): JSX.Element => {
  return (
    <div
      style={{
        borderBottom: `8px solid ${headBgColor !== undefined ? headBgColor : 'var(--gray-color)'}`,
      }}
      className={styles.pricing__card}
    >
      <div
        style={{
          background: headBgColor !== undefined ? headBgColor : 'var(--gray-color)',
        }}
        className={styles.head}
      >
        <IconRepository.CloudSuccessIcon size='80' fillColor='#fff' />
        <h3
          style={{
            marginTop: '20px',
          }}
        >
          {planType}
        </h3>
        <h4>{price}</h4>
      </div>
      <div className={styles.features}>
        {features.length > 0 &&
          features.map((feature, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.8rem',
                }}
              >
                <AiOutlineCheckCircle size='25' color='var(--WHITE_COLOR)' />
                <h5 key={String(index) + feature}>{feature}</h5>
              </div>
            )
          })}
        <div style={{ marginTop: '3rem' }}>
          <ButtonAtom
            label='Purchase Plan'
            buttonLook={ButtonLooks.OUTLINED}
            buttonType={ButtonTypes.PRIMARY}
          />
        </div>
      </div>
    </div>
  )
}

export default PricingCard
